import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { saveSlice } from '../../../store/slice/sceneSlice';
import { CloseIcon } from '../../../assets/SvgIcons';
import { saveProductDetails } from '../../../store/slice/prdouctSlice';
import VideoBG from '../../../assets/images/Videoskeleton.png'


export const ChangeSceneModal = ({ sceneModal, setCloseSceneModal, handleReloadScene }) => {
  const dispatch = useDispatch();
  const prdouctSlice = useSelector((state) => state.prdouctSlice );
  const sceneSlice = useSelector((state) => state.sceneSlice).slice;
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [iUUID, setIUUID] = useState("");
  
  useEffect(()=>{
      if("uuid" in sceneSlice ){
          setIUUID(sceneSlice.inventory.uuid)
    }
  },[sceneModal,sceneSlice]);

    const handleSubmit = async() =>{
      try {
        let _data = {
            iUUID:iUUID,
            projectuuid : prdouctSlice.details.uuid
        }

       let{ data } = await axios.post(`${configData.config.API_URL}/api/scene/change/${sceneSlice.uuid}` , _data ,{
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});

            dispatch(saveProductDetails({ 
              details : data.data.project,
              defaultInventoryList : data.data.defaultInventory
              }));
            
        close();
        handleReloadScene();
        
      } catch (error) {
          console.log(error.message);
      }
    }

    const close = () =>{
        setIUUID("");
        setCloseSceneModal(false);
    }
    
  const list = prdouctSlice.inventoryList.length > 0 ? prdouctSlice.inventoryList.filter(e => (e.fileType == "IMAGE" || e.fileType == "VIDEO" )) : [];       
  // const list = prdouctSlice.inventoryList.length > 0 ? prdouctSlice.inventoryList.filter(e => (e.fileType == "IMAGE")) : [];
  
  return (
    <>
    <Modal size='xl' show={sceneModal} onHide={close} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header className='modalheader'>
            <Modal.Title> Select New Scene</Modal.Title>
            <span className='closebtn' onClick={close}><CloseIcon /></span>
        </Modal.Header>

        <Modal.Body>
          <div className='row' >
             <div className='col-12 filelist ps-4'>
              <p className='listhead'>Select File</p>


              <div className='popupheading delete p-0 m-0 mb-0  d-flex flex-wrap gap-2 gallery forscene'>
              {list.length > 0 &&
               list.map((e, i) => {
                const fileExtension = e.path.substring(e.path.lastIndexOf('.') + 1);
                  return(
                  <div key={i} className= { iUUID == e.uuid ? 'projectlist-item content selected' : 'projectlist-item content'}>
                    <label>
                      <input
                      type="radio"
                      name="checkboxGroup"
                      value={e.name}
                      checked={iUUID == e.uuid}
                      onChange={()=>setIUUID(e.uuid)}
                    />
                    {fileExtension === 'mp4' && <img src={VideoBG} alt="MP4" />}
                    {['pdf', 'mp3', 'mp4'].indexOf(fileExtension) === -1 && (
                              <img src={e.path} alt="Default" />
                            )}
                    </label>
                    <p>{e.name}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                   </div>
                   ) 
                  })
                
              }
                  </div>
            </div>
          </div>

           
        </Modal.Body>
        <Modal.Footer className='footerbtn'>
        <Button variant="secondary" onClick={close}> Close </Button>
        <Button variant="primary" onClick={handleSubmit} > Save </Button>
        </Modal.Footer>
    </Modal>

    </>
  )
}

export const SceneVideoModal = ({ sceneVideoModal, setCloseSceneVideoModal }) => {
  const dispatch = useDispatch();
  const prdouctSlice = useSelector((state) => state.prdouctSlice );
  const sceneSlice = useSelector((state) => state.sceneSlice).slice;
  const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
  const [iUUID, setIUUID] = useState("");
  
  useEffect(()=>{
      if("uuid" in sceneSlice ){
          setIUUID(sceneSlice.video)
    }
  },[sceneSlice]);

    const handleSaveVideo = async() =>{
      try {
        let _data = {
            iUUID:iUUID
        }

        let{data} = await axios.post(`${configData.config.API_URL}/api/scene/video/${sceneSlice.uuid}` , _data ,{
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});

        dispatch(saveSlice({ detail:data.data }));
        close();
       
      } catch (error) {
          console.log(error.message);
      }
    }

    const close = () =>{
        setIUUID("");
        setCloseSceneVideoModal(false);
    }
    
  const list = prdouctSlice.inventoryList.length > 0 ? prdouctSlice.inventoryList.filter(e => e.fileType == "VIDEO") : [];       
  
  return (
    <>
    <Modal size='xl' show={sceneVideoModal} onHide={close} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton className='modalheader'>
            <Modal.Title>Select Video</Modal.Title>
            <span className='closebtn' onClick={close}><CloseIcon /></span>
        </Modal.Header>

        <Modal.Body>
          <div className='row' >
             <div className='col-9 filelist ps-4'>
              <p className='listhead'>Select File</p>

              <div className='popupheading delete p-0 m-0 mb-0  d-flex flex-wrap gap-2 gallery forscene'>
              {list.length > 0 ? list.map((e, i) => {
                  return(
                  <div key={i} className= { iUUID == e.uuid ? 'projectlist-item content selected' : 'projectlist-item content'}>
                    <label>
                      <input
                      type="radio"
                      name="checkboxGroup"
                      value={e.name}
                      checked={iUUID == e.uuid}
                      onChange={()=>setIUUID(e.uuid)}
                    />
                    <img src={VideoBG}/>
                    </label>
                    <p>{e.name}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                   </div>
                   ) 
                  })
                : null
              }
                  </div>
            </div>
          </div>

           
        </Modal.Body>
        <Modal.Footer className='footerbtn'>
        <Button variant="secondary" onClick={close}> Close </Button>
        <Button variant="primary" onClick={handleSaveVideo} > Save </Button>
        </Modal.Footer>
    </Modal>

    </>
  )
}
