import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { saveScene } from '../../../store/slice/prdouctSlice';
import { CopyShader } from 'three-stdlib';
import AudioBG from '../../../assets/images/Audioskeleton.png'
import VideoBG from '../../../assets/images/Videoskeleton.png'
import PdfBG from '../../../assets/images/PDFskeleton.png'
import { AddIcon, CloseIcon } from '../../../assets/SvgIcons';
import InventoryModal from './inventoryModal';
import { getLastElement } from '../../../utils';

const CreateNewSceneModal = ({ modal, setCloseModal }) => {
  const prdouctSlice = useSelector((state) => state.prdouctSlice );
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const dispatch = useDispatch();
  const [name, setName ] = useState("");
  const [content, setContent] = useState("");
  const [inventory, setInventoryModal] = useState(false);
  const [uuids, setUUIDS] = useState([]);
  const [projectUuid, setProjectUuid] = useState("");
  let _projectUUId = getLastElement(window.location.pathname);
  const [error, setError] = useState({
        name:false,
        scene:false
  });

  function checkValidation(){
       let notError = true;
       if(name.trim().length == 0 ){
          notError = false;
          setError((prevError) => ({
            ...prevError,
            name: true,
          }));
        }else{
         setError((prevError) => ({
           ...prevError,
           name: false,
         }));
       }

       if(uuids.length == 0 ){
          notError = false;
          setError((prevError) => ({
            ...prevError,
            scene: true,
          }));
        }else{
         setError((prevError) => ({
           ...prevError,
           scene: false,
         }));
       }

      return notError;

  };

    const handleSubmit = async() =>{
      try {
        setError({
          name:false,
          scene:null
         });
        const checkValidate =  checkValidation();
        if(checkValidate){
        let _data = {
          name:name,
          content:content,
          uuids
        }

        let { data } = await axios.post(`${configData.config.API_URL}/api/scene/create/${projectUuid}` , _data ,{
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});

        dispatch(saveScene({sceneList : data.data}));
        close();
          }

      } catch (error) {
          console.log(error.message);
      }
    }

    const close = () =>{
      setError({
        name:false,
        scene:false
       });
      setUUIDS([]);
      setProjectUuid("");
      setName("");
      setContent("");
      setCloseModal(false);
    }

    const selectScene = (e)=>{
         setProjectUuid(e.project.uuid);
         let allIUUID = [...uuids];
         const index = allIUUID.indexOf(e.uuid);
       
         if (index !== -1) {
           allIUUID.splice(index, 1);
         } else {
           allIUUID.push(e.uuid);
         }
        setUUIDS(allIUUID);
    }
    
  const list = prdouctSlice.inventoryList.length > 0 ? prdouctSlice.inventoryList.filter(e => (e.fileType == "IMAGE" || e.fileType == "VIDEO" )) : [];
  // const list = prdouctSlice.inventoryList.length > 0 ? prdouctSlice.inventoryList.filter(e => (e.fileType == "IMAGE")) : [];
  return (
    <>
    <Modal size='xl' show={modal} onHide={close} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header  className='modalheader'>
            <Modal.Title>Create New Scene</Modal.Title>
            <span className='closebtn' onClick={close}><CloseIcon/></span>
        </Modal.Header>

        <Modal.Body>
          <div className='row' >
             <div className='col-3 standardinput' >
                <label>
                <div>Scene Name<span className='asterisk'>*</span></div>
                <input 
                      type="text" 
                      placeholder='Scene Name' 
                      value={name} 
                      onChange={(e)=>setName(e.target.value)}  
                      className={error.name ? 'invalid-input' : ""}
                        />
                     <p className='error'>
                     {error.name ? 
                      "Please enter scene name"
                      : null }
                      </p>
                </label>
                
                <label className='mt-3'>
                  Content
                <input type="text"  placeholder='Content' value={content} onChange={(e)=> setContent(e.target.value)} />
                </label>
             </div>

             <div className='col-9 filelist ps-4'>
              <p className='listhead'>Select Files 
              <p className='error'>
               {error.scene ? 
                " Select at least one scene "
                : null } 
                </p> 
                </p>

           
              <div className= {list.length > 0 ? "popupheading delete p-0 m-0 mb-0  d-flex flex-wrap gap-2 gallery forscene" : "d-flex align-items-center justify-content-center w-100 h-100" } style={{fontWeight:"500" , fontSize:"20px", color:"#000"}}>
                  <div onClick={()=>setInventoryModal(true)} className={list.length > 0 ? 'projectlist-item content forupload' : 'projectlist-item content forupload newscene' }>
                    <div className='addicon'>
                      <AddIcon/>
                    </div>
                    <p>Upload Media</p>
                  </div>

                  { list.length > 0 &&
                     list.map((e, i) => {
                     const fileExtension = e.path.substring(e.path.lastIndexOf('.') + 1);
                     return(
                        
                        <div key={i} className= { uuids.includes(e.uuid) ? 'projectlist-item content selected' : 'projectlist-item content'}>
                          <label>
                            <input
                            type="checkbox"
                            name="checkboxGroup"
                            value={e.name}
                            checked={uuids.includes(e.uuid)}
                            onChange={()=>selectScene(e)}
                          />
                            {fileExtension === 'pdf' && <img src={PdfBG} alt="PDF" />  }
                            {fileExtension === 'mp3' && <img src={AudioBG} alt="MP3" />}
                            {fileExtension === 'mp4' && <img src={VideoBG} alt="MP4" />}
                            {['pdf', 'mp3', 'mp4'].indexOf(fileExtension) === -1 && (
                              <img src={e.path} alt="Default" />
                            )}
                          </label>
                          <p>{e.name}</p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                          </svg>
                        </div>
                       
                      ) 


                       })
                    }

              </div>
                  
            </div>
          </div>

           
        </Modal.Body>

        <Modal.Footer className='footerbtn mt-3'>
        <Button variant="secondary" onClick={close}> Close </Button>
        {list.length > 0 ?
          <Button variant="primary" onClick={handleSubmit} > Create </Button>
           :
          <Button variant="secondary" className="noDrop" title='No inventory found'> Create </Button>
          }
        </Modal.Footer>
    </Modal>

    <InventoryModal inventory={inventory} setInventoryModal={setInventoryModal} projectUUId={_projectUUId} />

    </>
  )
}

export default CreateNewSceneModal