import React, { useEffect, useState } from 'react'
import { CloseIcon, CorrectIcon, EditIcon, SideNav, SimApps } from '../assets/SvgIcons'
import { useNavigate } from 'react-router-dom';
import configSlice from '../store/slice/configSlice';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import CreatorLogo from '../assets/images/C.png'
import { useDispatch, useSelector } from 'react-redux';
import { saveProductDetails } from '../store/slice/prdouctSlice';
import { removeSlice } from '../store/slice/sceneSlice';

const EditorHeader = ({ setLoader, setLoaderText, roleType }) => {
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const prdouctSlice = useSelector((state) => state.prdouctSlice );
    const userSlice = useSelector((state) => state.userSlice );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [edit, setEdit] = useState(false);
    const [projectPublish , setProjectPublish] = useState("Publish");
    const [editable, setEditable] = useState(false);
    const [save, setSave] = useState("Save");

    useEffect(()=>{
        setName(prdouctSlice.details.name || "");
    },[prdouctSlice]);

    const handleSaveProject = async(type) =>{
        try {
            setLoader(true);
            setLoaderText(type == "preview" ? "Preview is loading" : "Saving");

            await axios.get(`${configData.config.API_URL}/api/project/SavePreview/${prdouctSlice.details.uuid}`, {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                  "Content-Type": "application/json"
             }});

             setTimeout(()=>{
                setSave("Saved");
                setLoader(false);
                setLoaderText(null);
                if(type == "preview"){
                    navigate(`/preview/project/${prdouctSlice.details.uuid}`)
                        }
            },1000);

        } catch (error) {
            console.log(error.message);
            if(type == "preview"){
                setLoaderText("Preview failed.");
            }else{
                setLoaderText("Saving failed.");
            }
            
            setTimeout(()=>{
                setLoader(false);
                setLoaderText(null);
            },1500);

        }finally{
            setTimeout(()=>{
                setSave("Save")
            },[3500])
        }
    }

    const saveProjectName =async()=>{
        try {
            let _data = { name : name }
            const {data} = await axios.post(`${configData.config.API_URL}/api/project/rename/${prdouctSlice.details.uuid}`, _data,{
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                  "Content-Type": "application/json"
                }});

            dispatch(saveProductDetails({ 
                    details : data.data.project,
                    defaultInventoryList : data.data.defaultInventory
                    }));

            setEdit(false);
            
        } catch (error) {
            console.log(error.message);
        }

    }

    const handleCancel = () =>{
        dispatch(removeSlice());
        navigate(`/projects`);
    }

    return (

        <>
             <header className='editorsheader'>
                <div className='d-flex align-items-center justify-content-between' style={{ width:"55%" }}>
                    <div className='sidetoggle'>
                        <div className='mainlogo LOGOHERE'>
                            <img src={CreatorLogo} alt='' />
                        </div>
                    </div>

                    <div className='project_title'>
                        <div className='namehere'>
                        {edit ?
                        <input type="text" value={name} onChange={(e)=>setName(e.target.value)} />
                        :
                        <Tooltip title={name}>    
                        <span>{name}</span>
                        </Tooltip>
                        }
                        </div>

                        {(roleType !== null && roleType !== "VIEWER") &&
                            (<> 
                            {
                                edit ?
                                <>
                                    {name.length > 0 && 
                                        <button className='svgbtn me-0' style={{ cursor:"pointer" }} onClick={saveProjectName} >
                                            <CorrectIcon />
                                        </button>
                                    }
                                    <button className='svgbtn ms-0' style={{ cursor:"pointer" }} onClick={()=>{setName(prdouctSlice.details.name || ""); setEdit(false)}}>
                                        <CloseIcon />
                                    </button>
                                </>
                                :
                                <span style={{ cursor:"pointer" }} onClick={()=>setEdit(true)}>
                                    <EditIcon />
                                </span>
                            }
                            </>)
                        }
                    </div>
                    
                </div>

                <div className='d-flex align-items-center justify-content-end' style={{ width:"40%" }}>
                <div className='button_group' >
                    {(roleType !== null && roleType !== "VIEWER") &&
                    <>
                        <button className='redstandardbtn me-2' onClick={()=>handleSaveProject("preview")} > Preview </button>
                        <button className='redstandardbtn withoutbg me-2' onClick={()=>handleSaveProject("save")}> {save} </button>
                    </>
                    }
                    <button className='svgbtn' onClick={handleCancel}><CloseIcon/></button>
                </div>
                </div>

            </header>
            
            { edit &&
            <div className='forinputsave' onClick={()=>{setName(prdouctSlice.details.name || ""); setEdit(!edit)}}>
            </div> }

        
        </>

    )
}

export default EditorHeader