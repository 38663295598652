import axios from "axios";
import {store} from "../store/store"

const apiClient = axios.create({
  headers: {
    "Content-Type": "application/json"
  },
});

const successResponseInterceptor = (response) => {
  return response?.data?.data;
};

const errorResponseInterceptor = (error) => {
  console.log(error);
};

const requestInterceptor = (req) => {
    const state = store.getState();
    const userData = state.userSlice;    // Accessing userSlice
    req.headers.Authorization = `Bearer ${userData.token}`;
  return req;
};

apiClient.interceptors.request.use(requestInterceptor);
apiClient.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export default apiClient;