import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AudioWaveform from './AudioWaveform';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';
import { CloseIcon } from '../../../assets/SvgIcons';

const HotspotData = ({ modal, setModal, hotspotModalData }) => {
  
  return (
    <>
      <Modal
        show={modal}
        size='xl'
        onHide={()=>setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header  className='modalheader'>
            <Modal.Title> {hotspotModalData.type} </Modal.Title>
            <span className='closebtn' onClick={()=>setModal(false)}><CloseIcon/></span>
        </Modal.Header>
        <Modal.Body >
        
        {hotspotModalData.type == "VIDEO" ? 
          <div className='videoplayer'>
           <video controls>
               <source src={hotspotModalData.data[hotspotModalData.type].path} type="video/mp4" />
               Your browser does not support the video tag.
            </video>
            </div>
          : 
          hotspotModalData.type == "AUDIO" ?
          <>
           <AudioWaveform audioUrl={hotspotModalData.data[hotspotModalData.type].path} />
          </>
            :
          hotspotModalData.type == "PDF" ?
           <PdfViewer pdfUrl={hotspotModalData.data[hotspotModalData.type].path} />
            :
          hotspotModalData.type == "TEXT" ?
            <div className='textmodal'>
              <p>
                {hotspotModalData.data[hotspotModalData.type]}              </p>
            </div>
            :
          hotspotModalData.type == "IMAGE" ?
            <ImageViewer list={hotspotModalData.data[hotspotModalData.type]} />
            :
            null   
         }
          
        </Modal.Body>
        {/* <Modal.Footer className='footerbtn'>
          <Button variant="secondary" onClick={()=>setModal(false)}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default HotspotData;
