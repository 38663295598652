import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useWindowDimensions from '../../../../utils/useWindowDimensions';

const DistributionChart = ({ data }) => {
  let {width , height} = useWindowDimensions();
    const chartData = {
        series: [data.published, data.active],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          colors: ['#CA353B','#333333'],
          labels: ['Published', 'Ongoing'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
    };
    return (
        <>
            <div id="chart" className='langchart' style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", gap: "20px" }}>
            <ReactApexChart 
               options={chartData.options} 
               series={chartData.series} 
               type="pie" 
               width={380} 
               />
            </div>
        </>
    );
};

export default DistributionChart;
