import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NoDataFound from '../NoDataFound';
import apiClient from '../apiClient';
import { USER_LIST } from '../apiClient/apiEndpoints';

const UserList = ({ search }) => {
  const { groupUUID } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userSlice);
  const token = userData?.token;
  const configData = useSelector((state) => state.configSlice);
  const [userList, setUserList] = useState([]);

  const fetchUsersList = async () => {
      let data = await apiClient.get(`${configData?.config?.ssoUrl}${USER_LIST}/${groupUUID}`);
      setUserList([...data]);
  };


  useEffect(() => {
    if (groupUUID.length > 0) {
      fetchUsersList();
    }
  }, [groupUUID]);




  return (
    <>
      <div className="row pt-4 mt-2">
        {userList.length > 0 ?
          userList.map((user, index) => {
            if (search.length && !user.name.toLowerCase().includes(search.toLowerCase())) return null;
            return (
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 px-2" key={index} onClick={() => navigate(`/dashboard/${user.uuid}`, { state: { name: user.name, email: user.name, uuid: user.uuid, avatar: user.avatar } })}  >
                <div className='usercardesign'>
                  {user.avatar ? (
                    <>
                      <div className="avatarimg">
                        <img src={user.avatar} alt="" referrerPolicy={'no-referrer'} />
                      </div>
                    </>
                  ) : (
                    <div className="initials-placeholder">
                      {user.name && user.name.split(" ").map((word, index) => index < 2 ? word[0].toUpperCase() : "").join("")}
                    </div>
                  )}
                  <h6 className='groupname'> {user.name} </h6>
                  <p>{user.email}</p>
                </div>
              </div>
            )
          })
          :
          <NoDataFound heading="No Users Found" nodataText="" />
        }

      </div>
    </>
  )
}

export default UserList 
