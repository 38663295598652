import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, AddSIcon, DeleteIcon, EditIcon, LinkIcon, DragListIcon, VideoIcon, CopyIcon, RefreshIcon } from '../assets/SvgIcons';
import CreateSceneModal from '../components/Pages/Modal/newSceneModal';
import configSlice from '../store/slice/configSlice';
import axios from 'axios';
import { saveSlice } from '../store/slice/sceneSlice';
import { saveScene } from '../store/slice/prdouctSlice';
import { ChangeSceneModal, SceneVideoModal } from '../components/Pages/Modal/ChangeSceneModal';
import UpdateHostpotModal from '../components/Pages/Modal/UpdateHostpotModal';
import { Tooltip } from '@mui/material';
import ConfirmBox from '../components/Pages/Modal/ConfirmBox';

const EditorRightbar = ({selectHotspotData, setSelectHotspotData, loadScene, saveSceneAngle, handleReloadScene, hotspotList, updateHotspotSize, handleCancelEditHotspot, roleType }) => {
    const dispatch = useDispatch();
   const userData = useSelector((state) => state.userSlice);
   const configData = useSelector((state) => state.configSlice);
   const prdouctSlice = useSelector((state) => state.prdouctSlice);
   const sceneSlice = useSelector((state) => state.sceneSlice).slice;
   const [selectedHotspotUUID, setSelectedHotspotUUID] = useState('select');
   const [activeKey, setActiveKey] = useState("all");
   const [modal, setCloseModal] = useState(false);
   const [saveDetailBtn, setSaveDetailBtn] = useState("Save Details");
   const [savePOVBtn, setSavePOVBtn] = useState("Save POV");
   const [sceneModal, setCloseSceneModal] = useState(false);
   const [videoModal, setCloseVideoModal] = useState(false);
   const [sceneVideoModal, setCloseSceneVideoModal] = useState(false);
   const [updateModal, setUpdateModal] = useState(false);
   const allSceneList = prdouctSlice?.sceneList || [];  
   const [size, setSize] = useState("0.20"); 
   const [timeoutId, setTimeoutId] = useState(null);
   const [sceneName, setSceneName] = useState("");
   const [content, setContent] = useState("");
   const [saveSequenceText, SetSaveSequenceText] = useState("Save Sequence");
   const [list, setList] = useState([]);
   const [draggedIndex, setDraggedIndex] = useState(null);
   const [show , setShow] = useState(false);
   const [activeSceneUuid, setActiveSceneUuid] = useState(null);
   const [actionTypeId , setActionTypeId] = useState({
                type:null,
                id:null
            });
   const [confirmBoxMsg, setConfirmBoxMsg] = useState({
                title:"",
                message:""
            });

   useEffect(()=>{
         setList(allSceneList);
   },[allSceneList]);

   useEffect(()=>{
         if("uuid" in sceneSlice ){
             setSceneName(sceneSlice.name);
             setContent(sceneSlice.content);
             setActiveSceneUuid(sceneSlice.uuid);
         }else{
            setActiveSceneUuid(null);
         }
   },[sceneSlice]);

   const updateLoadScene = (e) =>{
       loadScene(e);
       setSelectedHotspotUUID("select");
       setSelectHotspotData({});
      if(roleType !== null && roleType !== "VIEWER"){
          setTimeout(()=> setActiveKey("active"), 100);
      }
   }

   const changeActiveTab = (e) =>{
    if(e === "active" && !activeSceneUuid) return;
    setActiveKey(e);
   }

   const saveDetails = async() =>{
    let _data={
        sceneName,
        content
    }
    let{data} = await axios.post(`${configData.config.API_URL}/api/scene/update/detail/${sceneSlice.uuid}/${sceneSlice.project.uuid}`, _data ,{
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json"
        }});
        dispatch(saveSlice({detail:data.data.sceneDetail }));
        dispatch(saveScene({sceneList : data.data.sceneList}));
        setSaveDetailBtn("Saved")
        setTimeout(() => {
          setSaveDetailBtn("Save Details")
        }, 3000);
   }

   const cancelHotspot = () =>{
       setSelectedHotspotUUID("select");
       setSelectHotspotData({});
       
       handleCancelEditHotspot(true);
       setTimeout(() => {
        handleCancelEditHotspot(false)
       },500);
   }

   const getImageDimensions = async(url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = url;
    });
  };

   const selectHotspot = async(h) =>{

    if(h.target.value == "select"){
        setSelectedHotspotUUID("select");
        setSelectHotspotData({});
        
        return
    }

    let data = hotspotList.filter(e => e.uuid == h.target.value)[0];
    let {width, height} = await getImageDimensions(data.inventory.path)
    setSelectedHotspotUUID(h.target.value);
    setSelectHotspotData({...data});
    
    setSize(data.hotpotView.scaleX / width);
    
   }

   useEffect(()=>{
       if(hotspotList.length > 0 && selectedHotspotUUID != "select" ){
            let data = hotspotList.filter(e => e.uuid == selectedHotspotUUID)[0];
            setSelectHotspotData({...data});
        }
   },[hotspotList])

   const handleSaveHotspot = async(uuid) =>{
      try {
        let {width, height} = await getImageDimensions(selectHotspotData.inventory.path)

        let _data = {
            cordinate : {
            ...selectHotspotData.hotpotView,
            scaleX: width * parseFloat(size),
            scaleY: height * parseFloat(size),
            },
        }

        await axios.post(`${configData.config.API_URL}/api/hotspot/updateLocation/${uuid}`, _data, {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});

            setSelectedHotspotUUID("select");
            setSelectHotspotData({});
        
            handleReloadScene();

      } catch (error) {
         console.log(error.message);
      }
   }

   const handleSavePOV = () =>{
       saveSceneAngle();
       setTimeout(()=>{ setSavePOVBtn("Saved")}, 500);
       setTimeout(()=>{ setSavePOVBtn("Save POV")}, 3500);
    } 

    useEffect(() => {
    const timeoutId = setTimeout(() => {
        updateHotspotSize(size);
    }, 500);

    return () => {
        clearTimeout(timeoutId);
    };
    }, [size]);


    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
        setDraggedIndex(index);
      };
    
    const handleDragOver = (e, currentIndex) => {
        e.preventDefault();
        if (currentIndex == draggedIndex) return;

        setList(prevList => {
            const newList = [...prevList];
            const [draggedItem] = newList.splice(draggedIndex, 1);
            newList.splice(currentIndex, 0, draggedItem);
            return newList;
          });

        setDraggedIndex(currentIndex);

      };
    
    const handleDragEnd = () => {
        setDraggedIndex(null);
      };

    const saveSequence = async() =>{
       try {
        const uuids = list.map(e => e.uuid)
         let _data = {
            uuids
         }
         SetSaveSequenceText("Saving....")
         let{data} = await axios.post(`${configData.config.API_URL}/api/scene/saveSequence/${prdouctSlice.details.uuid}`, _data, {
             headers: {
                 Authorization: `Bearer ${userData.token}`,
                 "Content-Type": "application/json"
                }});

         dispatch(saveScene({sceneList : data.data}));          
                
        SetSaveSequenceText("Saved");
        setTimeout(()=>{ SetSaveSequenceText("Save Sequence") },2000);
       } catch (error) {
        console.log(error);
       }
      }

    const confirmSceneAction =(event, uuid, type) =>{
        event.stopPropagation();

        setConfirmBoxMsg({
            title: type == "sceneClone" ? "Clone Scene" : "Delete",
             message: type == "sceneClone" ?  "Do you really want to clone this scene?" : "Do you really want to delete this?"
        });

        setActionTypeId({
            type:type,
            id:uuid
           });
           
        setShow(true);
    }

    const closeModal = () =>{
        setActionTypeId({
            type:null,
            id:null
           });
        setConfirmBoxMsg({
            title:"",
             message:""
        });
        setShow(false);
    }

    const handleDelete = async() =>{
        try {

            if(actionTypeId.type == "sceneDelete"){

                let{data} = await axios.get(`${configData.config.API_URL}/api/scene/delete/${actionTypeId.id}/${prdouctSlice.details.uuid}`, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type": "application/json"
                    }});
                dispatch(saveScene({sceneList : data.data}));

            }else if(actionTypeId.type == "hotspotDelete"){

                await axios.get(`${configData.config.API_URL}/api/hotspot/delete/${actionTypeId.id}`, {
                    headers: {
                      Authorization: `Bearer ${userData.token}`,
                      "Content-Type": "application/json"
                    }});
                    setSelectedHotspotUUID("select");
                    setSelectHotspotData({});
                    

            }else if(actionTypeId.type == "sceneClone"){

                let{data} =  await axios.get(`${configData.config.API_URL}/api/scene/clone/${actionTypeId.id}/${prdouctSlice.details.uuid}`, {
                    headers: {
                      Authorization: `Bearer ${userData.token}`,
                      "Content-Type": "application/json"
                    }});

                    dispatch(saveScene({sceneList : data.data}));

            }
                
            handleReloadScene();
            closeModal();

        } catch (error) {
            console.log(error);
        }
    }

    const handleUpdateHotspot  = () =>{
        setSelectHotspotData({});
        handleReloadScene();
        setSelectedHotspotUUID("select");
    }

  return (
   <>
      <div className="sidmenu editorsidemenu rightside">
      {list && list.length > 0 ? 
        <Tab.Container id="my-tabs" activeKey={activeKey} onSelect={(e) =>changeActiveTab(e) }>
            <div className='scenetabs'>
                    <Nav className="tabsproduct updated toptab" variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="all">All scene</Nav.Link>
                        </Nav.Item>

                        {(sceneName !== "" && roleType !== null && roleType !== "VIEWER") &&
                            <Nav.Item >
                                <Nav.Link eventKey="active">Active scene</Nav.Link>
                            </Nav.Item>
                        }

                    </Nav>
            </div> 

            <Tab.Content className='w-100 '>
                <Tab.Pane eventKey="all" >
                    <div className='editorsidepanel all'>
                    <div className="row  mt-5">
                    <div className='scenelisthere'>

                        <ul>
                        {list.map((f, i)=>{
                            return(
                                <li
                                    key={i}
                                    draggable
                                    onDragStart={(event) => handleDragStart(event, i)}
                                    onDragOver={(event) => handleDragOver(event, i)}
                                    onDrop={handleDragEnd}
                                    onDragEnd={handleDragEnd}
                                    className={activeSceneUuid === f.uuid ? 'sceneitem active' : 'sceneitem'}
                                    onClick={() => updateLoadScene(f)}
                                >
                                <div className='sceneimg'>
                                {f.inventory.fileType == "VIDEO" ? 
                                <video src={f.inventory.path} controls={false} />                 
                                  : 
                                <img 
                                   loading='eager'
                                   src={f.inventory.path}
                                /> 
                                }

                                <Tooltip title={f.name} >
                                <p>{f.name}</p>
                                </Tooltip>

                                </div>
                                   {roleType !== null && roleType !== "VIEWER" &&  
                                    <>
                                        <span title="clone this scene " onClick={(e)=>confirmSceneAction(e, f.uuid, "sceneClone")}> <CopyIcon/> </span>
                                        {activeSceneUuid !== f.uuid && <span onClick={(e)=>confirmSceneAction(e, f.uuid, "sceneDelete")}> <DeleteIcon/> </span> }
                                    </>
                                   }

                                </li>
                            )
                        })}
                        {draggedIndex !== null && (
                            <li className="placeholder" key="placeholder">    </li>
                        )}
                        </ul>
                    </div>
                    </div>  
                    </div>  
                </Tab.Pane>

                <Tab.Pane eventKey="active" >
                    <div className='editorsidepanel'>
                    <div className='activescenedata  mt-5'>
                     <div className='inputdata pt-1'>
                     <div className='standardinput' >
                        <label className='mt-0'>
                           Scene :
                        <input type="text" name="content" placeholder='Scene' value={sceneName} onChange={(e)=> setSceneName(e.target.value)} />
                        </label>
                        <label className='mt-2'>
                           Content :
                        <input type="text" name="content" placeholder='Content' value={content} onChange={(e)=> setContent(e.target.value)} />
                        </label>
                     </div>
                     <button className='redstandardbtn' onClick={saveDetails} > {saveDetailBtn} </button>
                     </div>

                    </div>

                        {/* <div className='uploadactions mt-3'>
                            <span>Upload Video :</span>
                            <span className='uploadicon' onClick={()=>setCloseSceneVideoModal(true)}>
                                <LinkIcon/>
                            </span>
                        </div> */}

                        <div className='uploadactions'>
                            <span>Change scene :</span>
                            <span className='uploadicon' onClick={()=>setCloseSceneModal(true)}>
                                <LinkIcon/>
                            </span>
                        </div>

                        <div className='uploadactions'>
                            <span>Camera angle :</span>
                            <span className='uploadicon px-2' style={{ width:"fit-content" }} onClick={handleSavePOV} >
                             {savePOVBtn}
                            </span>
                        </div>

                        <div className='uploadactions'>
                            <span>Hotspots :</span>
                            <select className='hotspotList-dropDown' style={{ fontSize:"13px" }} id=">HotspotList" name=">HotspotList" onChange={selectHotspot} defaultValue={selectedHotspotUUID}>
                                <option value="select">Select</option>
                                {hotspotList.map((e,index)=>{
                                 return(
                                    <option value={e.uuid} key={index} selected={e.uuid === selectedHotspotUUID} > {e.name} </option>
                                    )
                                })}
                            </select>
                        </div>

                        {selectHotspotData.uuid &&
                            <>
                            <div className='uploadactions'>
                            <span>Edit Hotspot Details :</span>
                            <span className='uploadicon' style={{ padding:"5px" }} onClick={()=>setUpdateModal(true)}>
                                    <EditIcon/>
                                </span>
                            </div>

                            <div className='uploadactions'>
                            <span>Hotspot Type:</span>
                            <span className='uploadicon inputtype' style={{ width:"fit-content", padding:"2px 5px" }} >
                                {selectHotspotData.type}
                                </span>
                            </div>

                            <div className='cordinates'>
                                <div className='uploadactions'>
                                <span>Range:</span>
                                <span className='uploadicon inputtype' style={{ width:"fit-content", padding:"2px 5px" }} >
                                    <input type='range' onChange={(e) => setSize(e.target.value)} value={size} min="0.05" max="1" step="0.05" />
                                </span>
                                </div>

                                <div className='uploadactions'>
                                <span>X:</span>
                                <span className='uploadicon inputtype' style={{ width:"fit-content", padding:"2px 5px" }} >
                                    {Number(selectHotspotData.hotpotView.x).toFixed(3)}
                                    </span>
                                </div>

                                <div className='uploadactions'>
                                <span>Y:</span>
                                <span className='uploadicon inputtype' style={{ width:"fit-content", padding:"2px 5px" }} >
                                {Number(selectHotspotData.hotpotView.y).toFixed(3)}
                                    </span>
                                </div>

                                <div className='uploadactions'>
                                <span>Z:</span>
                                <span className='uploadicon inputtype' style={{ width:"fit-content", padding:"2px 5px" }} >
                                    {Number(selectHotspotData.hotpotView.z).toFixed(3)}
                                    </span>
                                </div>

                            </div>

                            <div className='uploadactionsbtns mt-2'>                   
                            <span className='uploadicon px-2' style={{ width:"fit-content" }} onClick={()=>handleSaveHotspot(selectHotspotData.uuid)} >
                                Save
                            </span>
                            <span className='uploadicon px-2' style={{ width:"fit-content" }} onClick={(e)=>confirmSceneAction(e, selectHotspotData.uuid, "hotspotDelete")} >
                                Delete
                            </span>
                            <span className='uploadicon px-2' style={{ width:"fit-content" }} onClick={cancelHotspot} >
                                Cancel
                            </span>
                            </div>
                                
                            </>                             
                        }

                        </div>

                </Tab.Pane>
            </Tab.Content>

        </Tab.Container>
         :
         <div className='d-flex align-items-center justify-content-center h-100 w-100'>
           <div className='projectlist-item content forupload' style={{ height: "100px", width:"150px", borderColor:"#33333350" }} onClick={()=>setCloseModal(true)}>
               <div className='addicon'>
                   <AddIcon/>
               </div>
               <p className='text-center'>Add Scene</p>
           </div>
         </div>
        }
         
        {(list && list.length > 0 && activeKey == "all" && roleType !== null && roleType !== "VIEWER") && (
            <div className='sceneactions'>
                <button className='redstandardbtn' onClick={saveSequence} > {saveSequenceText} </button>
                <button className='redstandardbtn ms-2' onClick={()=>setCloseModal(true)} > <AddSIcon/> </button>
            </div>
        )}

      </div> 

      <CreateSceneModal modal={modal} setCloseModal={setCloseModal} />
      <ChangeSceneModal sceneModal={sceneModal} setCloseSceneModal={setCloseSceneModal} handleReloadScene={handleReloadScene} />
      <SceneVideoModal sceneVideoModal={sceneVideoModal} setCloseSceneVideoModal={setCloseSceneVideoModal} />
      <UpdateHostpotModal updateModal={updateModal} setUpdateModal={setUpdateModal}  selectHotspotData={selectHotspotData} handleReloadScene={handleUpdateHotspot} />
      <ConfirmBox title={confirmBoxMsg.title} message={confirmBoxMsg.message} show={show} setShow={closeModal} handleConfirm={handleDelete} />
    </>
  );
};

export default EditorRightbar;