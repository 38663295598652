import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    details:{},
    inventoryList:[],
    sceneList:[],
    defaultInventoryList:[],
}

const productSlice = createSlice({
    name:"productSlice",
    initialState,
    reducers:{
        saveProductDetails:(state,action)=>{
            state.details = action.payload.details,
            state.inventoryList = action.payload.details.inventories,
            state.sceneList = action.payload.details.scenes,
            state.defaultInventoryList = action.payload.defaultInventoryList
        },
        saveInventory: (state,action)=>{
            state.inventoryList = action.payload.inventoryList
        },
        saveScene: (state, action)=>{
            state.sceneList = action.payload.sceneList
        }
    }
});

export const { saveProductDetails, saveInventory, saveScene } = productSlice.actions;
export default productSlice.reducer;