import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AudioWaveform from '../Modal/AudioWaveform';
import ImageViewer from '../Modal/ImageViewer';
import PdfViewer from '../Modal/PdfViewer';
import { CloseIcon } from '../../../assets/SvgIcons';


const HotspotData = ({ modal, setModal, hotspotModalData }) => {
  
  return (
    <>
      <Modal
        show={modal}
        size='xl'
        onHide={()=>setModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header  className='modalheader'>
            <Modal.Title> { hotspotModalData.type } </Modal.Title>
            <span className='closebtn' onClick={()=>setModal(false)}><CloseIcon/></span>
        </Modal.Header>
        <Modal.Body >
        
        {hotspotModalData.type == "VIDEO" ? 
          <div className='videoplayer'>
           <video controls>
               <source src={hotspotModalData.assetLink+hotspotModalData.data} type="video/mp4" />
               Your browser does not support the video tag.
            </video>
            </div>
          : 
          hotspotModalData.type == "AUDIO" ?
          <>
           <AudioWaveform audioUrl={hotspotModalData.assetLink+hotspotModalData.data} />
          </>
            :
          hotspotModalData.type == "PDF" ?
           <PdfViewer pdfUrl={hotspotModalData.assetLink+hotspotModalData.data} />
            :
          hotspotModalData.type == "TEXT" ?
            <div className='textmodal'>
              <p>
                {hotspotModalData.data}
              </p>
            </div>
            :
          hotspotModalData.type == "IMAGE" ?
            <ImageViewer list={hotspotModalData.data} assetLink={hotspotModalData.assetLink} />
            :
            null   
         }
          
        </Modal.Body>

      </Modal>
    </>
  );
};

export default HotspotData;
