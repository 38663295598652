export const USER_DASHBOARD = "/api/dashboard/user";
export const TEAM_DASHBOARD = "/api/dashboard/team";
export const USER_LIST = "/api/user/list";
export const GROUP_LIST = "/api/user/group/list";
export const PROJECT_LIST = "/api/project/list";
export const TRASH_PROJECT_LIST = "/api/project/trash";
export const GROUP_USER_LIST = "/api/user/list";
export const MOVE_TO_TRASH = "/api/project/move/trash";
export const CLONE_PROJECT = "/api/project/clone";
export const SHARE_PROJECT = "/api/project/share";
export const SHARE_PROJECT_USERS = "/api/project/share/user/list";