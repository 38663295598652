import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import { OrbitControls } from 'three-stdlib';
import default360 from "../../../assets/images/default360.jpg";
import { useParams } from 'react-router-dom';
import { previewApi } from '../../../env';
import PublishedHotspotModal from "./PublishedHotspotModal"


const ProjectPublished = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const [texture, setTexture] = useState(new TextureLoader().load(default360));
  const [scene , setScene] = useState(new THREE.Scene());
  const [cameraView , setCameraView] = useState({x:0,y:0,z:0});
  const [camera , setCamera] = useState(new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000));
  const [renderer , setRenderer] = useState(new THREE.WebGLRenderer());
  const mountRef = useRef();
  const controls = useRef();
  const videoRef = useRef(null);
  const { uuid } = params;
  const [sceneDetails, setSceneDetails] = useState({});
  const [allSceneDetails, setAllSceneDetails] = useState([]);
  const [hotspotList, setHotspotList] = useState([]);
  const [hotspotModalData, setHotspotModalData] = useState({});
  const [modal, setModal] = useState(false);
  const [fileExist, setFileExist] = useState(true);
  const loaderRef = useRef(null);
  const [assetLink, setAssetLink] = useState("");
  const [textureLoad , setTextureLoad] = useState(false);
  
  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov'];
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'];

  const endsWithAny = (str, extensions) => {
    return extensions.some(extension => str.toLowerCase().endsWith(extension));
  }

  const updateScene = async() =>{

      let existingVideo = videoRef.current;
      if (existingVideo) {
        existingVideo.pause();
        existingVideo.src = "";
        existingVideo.load();
        videoRef.current = null;
      }

      let filePath = sceneDetails.background;
      let isFileImage = await endsWithAny(filePath, imageExtensions)

      if(isFileImage){
        setTexture(new TextureLoader().load(`${assetLink+filePath}`));
      }else{
        const video = document.createElement('video');
        video.src = assetLink+filePath;
        video.crossOrigin = 'anonymous';
        video.loop = true;
        video.muted = false;
        video.play();
        videoRef.current = video;
        setTexture(new THREE.VideoTexture(video))
      }
      
      setCameraView({ 
        x:sceneDetails.xAngle, 
        y:sceneDetails.yAngle,  
        z:sceneDetails.zAngle 
      });
      
      setHotspotList([...sceneDetails.hotspot]);
      setTextureLoad(true);
      
  };

  useEffect(()=>{
    setHotspotList([]);
    if("uuid" in sceneDetails ){
           updateScene();
         }
  },[sceneDetails]);

  const handleHotspotClick = (event, hotspotSprite) => {
    // Add Hotspot Button is active or not
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(hotspotSprite);
    if (intersects.length > 0 ) {
          setHotspotModalData({});
          if(hotspotSprite.userData.hotspotType == "LINK"){
               let selectSceneUUID = hotspotSprite.userData.hotspotData.data[0];
               let findScene = allSceneDetails.filter(e => e.uuid == selectSceneUUID)[0]
               if(findScene){
                loaderRef.current.style.display = 'flex';
                setSceneDetails({...findScene})
               }
          } else if(hotspotSprite.userData.hotspotType == "IMAGE"){
            setModal(true)
            setHotspotModalData({
              assetLink,
              type:hotspotSprite.userData.hotspotType,
              data:hotspotSprite.userData.hotspotData.data
            });
          }else{
              setModal(true)
              setHotspotModalData({
                assetLink,
                type:hotspotSprite.userData.hotspotType,
                data:hotspotSprite.userData.hotspotData.data[0]
              });
            } 
    }
    
  };

  const handleHotspotHover = (event, hotspotSprite) => {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    
   // Reset scale for all hotspots
    scene.children.forEach((child) => {
      if (child.userData.type === 'hotspot') {
            // child.scale.set(child.userData.originalScale.x, child.userData.originalScale.y, child.userData.originalScale.z);
            child.scale.copy(child.userData.originalScale);
         }
     });

    if (intersects.length > 0) {
      const intersectedHotspot = intersects[0].object;
      if (intersectedHotspot.userData.type === 'hotspot') {
        intersectedHotspot.scale.set(
          intersectedHotspot.userData.originalScale.x * 1.5,
          intersectedHotspot.userData.originalScale.y * 1.5,
          intersectedHotspot.userData.originalScale.z * 1.5
        );
      }
    }
  };

const loadHotspot = async(hotspotList) =>{
  if(hotspotList.length > 0){ 

    for(let i in hotspotList){
      const e = hotspotList[i];

            const hotspotTexture = new THREE.TextureLoader().load(`${assetLink+e.icon}`);
        
          hotspotTexture.minFilter = THREE.LinearFilter;
          hotspotTexture.magFilter = THREE.LinearFilter;
          hotspotTexture.mapping = THREE.EquirectangularReflectionMapping;
          hotspotTexture.encoding = THREE.sRGBEncoding;
          hotspotTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
          const hotspotMaterial = new THREE.SpriteMaterial({ 
            map: hotspotTexture, 
            // color: 0xffffff, // set color of hotspot
            // transparent: true,
            // opacity: 1,
            side: THREE.DoubleSide,
            toneMapped : false,
            rotation: 0,  // rotate hotspot
            //sizeAttenuation: true,
            depthTest: false,  // Hide some part of hotspot
          });       
          const hotspotSprite = new THREE.Sprite(hotspotMaterial);
          hotspotSprite.scale.set(e.scaleX, e.scaleY, e.scaleX);
          hotspotSprite.position.set(e.x, e.y, e.z);

          // Add click event for hotspots
            hotspotSprite.userData = {
              type: 'hotspot',
              hotspotData: e,
              hotspotType: e.type,
              originalScale: new THREE.Vector3(e.scaleX, e.scaleY, e.scaleZ)
            };
          
        // Save the reference to handleHotspotClick
            const clickHandler = (event) => handleHotspotClick(event, hotspotSprite);
            renderer.domElement.addEventListener('click', clickHandler);
            hotspotSprite.userData.clickHandler = clickHandler;
            scene.add(hotspotSprite);


            const hoverHandler = (event) => handleHotspotHover(event, hotspotSprite);
            renderer.domElement.addEventListener('mousemove', hoverHandler);
    }

     }
}

  useEffect(()=>{
    renderer.domElement.removeEventListener('click', handleHotspotClick);
    renderer.domElement.removeEventListener('mousemove', handleHotspotHover);
    

    // Remove Hotspot and it's click function
      while (scene.children.length > 0) {
        const child = scene.children[0];
        renderer.domElement.removeEventListener('click', child.userData.clickHandler);
        child.material.map.dispose(); // Dispose of hotspot texture
        scene.remove(child);
      }

      loadHotspot(hotspotList);

    return () => {
      renderer.domElement.removeEventListener('click', handleHotspotClick);
      renderer.domElement.removeEventListener('mousemove', handleHotspotHover);
        
    // Remove all hotspots
      scene.children.forEach((child) => {   
       if (child.userData.type === 'hotspot') {
        renderer.domElement.removeEventListener('click', child.userData.clickHandler);
        child.material.map.dispose();
        scene.remove(child);
        }
     });

    };



  },[hotspotList]);

  const getProjectPublishedData = async() =>{
     try {
      let { data } = await axios.get(`${previewApi}/api/project/viewPublished/${uuid}`,{
        headers: {
          "Content-Type": "application/json"
        }});
     
        if(data.find){
          setFileExist(true);
          setAssetLink(data.data.link);
          if(data.data.data.length > 0){
            setAllSceneDetails([...data.data.data]);
            setSceneDetails({...data.data.data[0]});
          }
        }else{
          setFileExist(false);
        }

     } catch (error) {
       console.log(error)
     }
  };

  useEffect(()=>{
          uuid && getProjectPublishedData(); 
  },[uuid]);

    useEffect(() => {
      if (!mountRef.current) return;
    // Set up Three.js scene, camera, and renderer
     renderer.setSize(window.innerWidth, window.innerHeight);
     renderer.setPixelRatio(window.devicePixelRatio);
     
     texture.mapping = THREE.EquirectangularReflectionMapping;
     texture.encoding = THREE.sRGBEncoding;
     texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

     mountRef.current.appendChild(renderer.domElement);

    // Create a sphere geometry for the 360 image
    const geometry = new THREE.SphereGeometry(500, 60, 40);
    geometry.scale(-1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    // Set up OrbitControls
    controls.current = new OrbitControls(camera, renderer.domElement);
    controls.current.enableZoom = true;
    controls.current.minDistance = 0.1; 
    controls.current.maxDistance = 250;
    controls.current.zoomSpeed = 2.0;
    controls.current.rotateSpeed = -0.5;
    controls.current.autoRotate = false;
    // controls.current.maxDistance = 60;
    // controls.current.autoRotateSpeed = 2;

    controls.current.target0.copy(new THREE.Vector3(0, 0, 0));
    controls.current.position0.copy(new THREE.Vector3(cameraView.x, cameraView.y, cameraView.z));
    controls.current.zoom0 = 1;
    controls.current.reset();

    // Set the camera position
      // camera.position.set(cameraView.x, cameraView.y, cameraView.z);
      // camera.lookAt(0,0,0);
      // camera.position.z = 5;

      let cameraZView = cameraView.z.toFixed(3) == 0.00 ? 0.05 : cameraView.z.toFixed(3);
      camera.position.z =  cameraZView;

    // Animation function
    const animate = () => {  
      requestAnimationFrame(animate); 
      controls.current.update();
      renderer.render(scene, camera);
    };

    // Handle window resize
      window.addEventListener('resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });

      animate();

      if (textureLoad){ 
        setTimeout(()=>{
          loaderRef.current.style.display = 'none'; 
        },1000)
      }

    // Cleanup on component unmount
          return () => {
          controls.current.dispose();
          texture.dispose();
          scene.traverse((object) => {
            if (object.material) {
              object.material.dispose();
            }
          });

      if (mountRef.current && mountRef.current.contains(renderer.domElement)) {
              mountRef.current.removeChild(renderer.domElement);
          }

        }

    }, [texture]);

  return(
    <>
    {fileExist ?
     <div className='sceneLoader'>
       <div ref={mountRef} />

       <div ref={loaderRef} className='loaderhere sceneChangeLoader'>
            <div id="container">
              <span className="loading-circle sp1">
                <span className="loading-circle sp2">
                  <span className="loading-circle sp3"></span>
                </span>
              </span>
            </div>
       </div>

     </div>
         :
     <p>File Does not exist</p>
    }

     <PublishedHotspotModal modal={modal} setModal={setModal} hotspotModalData={hotspotModalData} />

      

    </>
    );
};

export default ProjectPublished;