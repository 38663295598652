import React from 'react'
import { useState } from 'react';


const filterKeys = [
  {
    "label": "All",
    "key": "all"
  },
  {
    "label": "W",
    "key": "week"
  },
  {
    "label": "M",
    "key": "month"
  },
  {
    "label": "6M",
    "key": "half-year"
  },
  {
    "label": "Y",
    "key": "year"
  },
];

const DateRangeSelector = ({selected , setSelected}) => {
  return (
    
      <div className="rangeselect">
        {filterKeys.map((item) => {
          return <div onClick={()=>setSelected(item.key)} key={item.key} className={`rangetype ${selected === item.key ? 'active' : ''}`}>
            <p>{item.label}</p>
          </div>
        })}
      </div>
    
  )
}

export default DateRangeSelector