import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const humanReadableMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  return minutes ;
};

export const humanReadable = (seconds) => {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  const parts = [
      { label: 'Days', value: days },
      { label: 'Hrs', value: hours },
      { label: 'Mins', value: minutes },
      { label: 'Secs', value: seconds },
  ];
  const nonZeroParts = parts.filter(part => part.value > 0);
  const result = nonZeroParts.slice(0, 2).map(part => `${part.value} ${part.label}`).join(' ');
  return result || '0 Secs';
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
