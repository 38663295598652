import React, {useState, useEffect} from 'react'
import DateRangeSelector from '../../filterDataRange/DateRangeSelector';
import { AiOutlineBook } from 'react-icons/ai';
import { MdBusiness } from 'react-icons/md';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DistributionChart from '../DashComponents/Charts/DistributionChart';
import TimeSpentChart from '../DashComponents/Charts/TimeSpentChart';


const Dashboard = ({ teamData, filter, setFilter }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const userData = useSelector((state) => state.userSlice);
        const configData = useSelector((state) => state.configSlice);
        const { groupUUID } = useParams();

        const dashboardCard = [
        {
            id: 1,
            count: teamData.total,
            title: 'Projects',
            icons: <AiOutlineBook />
        },
        {
            id: 2,
            count: teamData.group.groups,
            title: 'SubGroups',
            icons: <MdBusiness />
        },
        {
            id: 3,
            count: teamData.group.users,
            title: 'Users',
            icons: <FaUsers />
        }
        ];

        const [groupData , setGroupData] = useState({
            avatar:"",
            name:""
        });

        useEffect(()=>{
            if(location.state && "uuid" in location.state ){
                setGroupData({...location.state})
            }
        },[groupUUID, location.state])

        return ( 
        <>                         
            <div className="row">
                <div className='bg-grey-main dashboard p-3 px-0' >
                    <div className="container-fluid">
                        <div className='row py-2 mt-2'>
                            <div className='col-md-6 d-flex align-items-center'>
                            {(groupUUID !== userData.user.group.uuid) &&
                                <button className='backbtn cursor-pointer ps-2 mt-2' title="Back" style={{ fontSize: '16px' }} onClick={() => navigate(-1)}> <FaArrowLeftLong /> Back </button>
                            }
                            </div>
                            <div className='col-md-6 d-flex align-items-center'>
                                <DateRangeSelector selected={filter} setSelected={setFilter} />
                            </div>
                        </div>
                        <div className="col-md-12 mb-2" >
                        <div className='datacardesign py-2'>
                        <div className="row justify-content-between">
                            <div className='col-md-5 d-flex align-itmes-center'>
                                <div className='cardimages d-flex align-items-center gap-3 ps-3'>
                                    
                                        {groupData.avatar ?
                                            <div className="groupcardesign m-0" style={{ height: "auto" }}>
                                            <div className='avatarimg'>
                                                <img src={groupData.avatar} referrerPolicy={'no-referrer'} alt='' />
                                            </div>
                                            </div>
                                            :
                                            <div className="usercardesign m-0" style={{ height: "auto" }}>
                                            <div className='initials-placeholder'>
                                                {groupData.name.split(' ').map(word => word[0]).slice(0, 2).join('')}
                                            </div>
                                            </div>
                                        }
                                    
                                    <h4 className=" group-user-name m-0"> {groupData.name} </h4>
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className='dashstatdata'>
                                    { dashboardCard.map((edata, index) => (
                                        <div className="welcometext" key={index+1} >
                                        <p>{edata.title}</p>
                                        <h4>{edata.count}</h4>
                    
                                        </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        
                        <div className="row admincharts mt-2">  
                            <div className="col-md-6 mt-2">
                                        <div className="cardbox overflow-hidden">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="mb-0"> Time Spent / User Engagement</h5>
                                                    <p className="mb-1">
                                                        Total Time : {teamData.totalTime}
                                                    </p>
                                                </div>
                                                <TimeSpentChart data={teamData}   />
                                        </div>
                            </div>
                            <div className="col-md-6 mt-2">
                                        <div className="cardbox overflow-hidden">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="mb-1">Projects</h5>
                                                </div>
                                                <DistributionChart data={teamData} />
                                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        
        </>
        )

    }
    export default Dashboard