import React, {useState, useEffect} from 'react' 
import { CancelIcon, SearchesCIcon } from '../../assets/SvgIcons'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ConfirmBox from './Modal/ConfirmBox';
import { useDispatch } from 'react-redux';
import { saveTrash } from '../../store/slice/trashSlice';
import apiClient from '../../apiClient';
import { TRASH_PROJECT_LIST } from '../../apiClient/apiEndpoints';
import { defaultLogo } from '../../env';

const Trash = ({}) => {
    const [list, setList] = useState([]);
    const dispatch = useDispatch();
    const [selectedProject, setSelectedProject] = useState([]);
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const trashData = useSelector((state)=> state.trashSlice );
    const [search, setSearch] = useState("");
    const [title, SetTitle] = useState("");
    const [msg, SetMsg] = useState("");
    const [actionType, setActionType] = useState(null);
    const [moveTrashId, setMoveTrashId] = useState([]);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const trashProjectList= async()=>{
            let data = await apiClient.get(`${configData.config.API_URL}${TRASH_PROJECT_LIST}`);
                dispatch(saveTrash(data));
                data.length === 0 && navigate('/projects')
    };

    useEffect(()=>{
         trashProjectList();
    },[]);

    const handleConfirm = async() =>{
        let data = {
            list:[...moveTrashId]
        }

        await axios.post(`${configData.config.API_URL}/api/project/${actionType}`,data,{
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});

        closeModal();
        trashProjectList();
    }

    const selectTrashProject =  (e) =>{
        const isProjectSelected = selectedProject.includes(e);
        if (isProjectSelected) {
          const updatedProjects = selectedProject.filter((project) => project !== e);
          setSelectedProject(updatedProjects);
        } else {
          setSelectedProject([...selectedProject, e]);
        }
    }

    const confirmRestoreOrDelete =(e, type) =>{
        if(type == "restore"){
            setActionType("restore");
            SetTitle("Restore");
            SetMsg("Do you really want to restore this project?");
            setMoveTrashId([e]);
            setShow(true);
        } else if(type == "restoreAll"){
            SetTitle("Restore");
            setActionType("restore");
            SetMsg("Do you really want to restore selected projects?");
            setMoveTrashId(e);
            setShow(true);
        } else if(type == "deleteAll"){
            SetTitle("Delete");
            setActionType("delete");
            SetMsg("Do you really want to delete selected projects?");
            setMoveTrashId(e);
            setShow(true);
        }
    }

    const closeModal = () =>{
        setShow(false);
        setMoveTrashId([]);
        SetTitle("");
        SetMsg("");
        setActionType(null);
    }

    return (
        <>
        <div className='container-fluid p-4 pt-2 bg-grey-main'>
         
             <div className="row pt-3 mb-3 ps-0">
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                        <div className="position-relative">
                            <SearchesCIcon />
                            {search.length > 0 &&  
                            <span className='searchiconcancel' onClick={()=>setSearch("")} >
                            <CancelIcon />
                            </span>
                            }
                            <input
                                type="text"
                                name="searchName"
                                value={search}
                                onChange={(e)=>setSearch(e.target.value)}
                                className="form-control"
                                placeholder="Search"
                                autoComplete="off"
                            />
                        </div>
                        </form>
                    </div>
                    <div className='col-md-6 d-flex align-items-center justify-content-end'>
                        { selectedProject.length > 0  &&
                        <>
                        <button className='redstandardbtn me-3' onClick={selectedProject.length > 0 ? ()=>confirmRestoreOrDelete(selectedProject , "restoreAll") : ()=>console.log("") } > Restore </button>
                        <button className='redstandardbtn' onClick={selectedProject.length > 0 ? ()=>confirmRestoreOrDelete(selectedProject , "deleteAll") : ()=>console.log("") } > Delete </button>
                        </>
                        }
                    </div>
             </div>

             <div className='row pt-2 pt-1' >
            {trashData.list.length > 0 ?
               trashData.list.map((e,index)=>{
                if(search.length &&  !(e.name.toLowerCase().includes(search))) return null;
                const hasImage = Array.isArray(e.scenes) && e.scenes.length > 0;

                return(
                <div className='col-xxl-2 col-xl-2  col-lg-3 col-md-4 col-sm-6 col-12 mb-2 p-2' key={index}  >
                    <div className='projectbody'>

                    {hasImage ?
                        <div className={`projectthumb ${(!hasImage)? "no-thumb" : ""} `} onClick={()=>selectTrashProject(e.uuid)} >
                            <img src={e.scenes[0].inventory.path} onError={(e) => { e.target.src = defaultLogo }} alt="thumbnail" loading='lazy' />
                            <input className="form-check-input standardcheckbox" checked={selectedProject.includes(e.uuid) ? true : false}  type="checkbox" value="" />
                        </div>
                            :
                        <div className='projectthumb' onClick={()=>selectTrashProject(e.uuid)} >
                            <img src={defaultLogo} />
                            <input className="form-check-input standardcheckbox" checked={selectedProject.includes(e.uuid) ? true : false}  type="checkbox" value="" />
                        </div>
                    }

                        <div className='projectinfo'>
                            <div className='projectname'>
                                <Tooltip title={e.name}>
                                <p>{e.name}</p>
                                </Tooltip>
                                <div className='userdrop remove' onClick={()=>confirmRestoreOrDelete(e.uuid , "restore")}>
                                    Restore
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
               })
               :
               null
        }
             </div>
              
        </div>

        <ConfirmBox title={title} message={msg} show={show} setShow={closeModal} handleConfirm={handleConfirm} />

        </>
    )
}

export default Trash