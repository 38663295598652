import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { DashBoardIcon, MyDashBoardIcon, ProjectsIcons, TrashIcon } from '../assets/SvgIcons';
import SimulanisIcon from '../assets/images/favicon.png'
import DownloadCreator from './DownloadCreator';

const Sidebar = ({ sideBar, setSideBar }) => {
  const [list, setList] = useState([]);
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const trashData = useSelector((state) => state.trashSlice);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const [activeTab, setActiveTab] = useState('dashboard');
  const[creatorPop, setCreatorPop] = useState(false)
  useEffect(() => {
    if (currentPath.startsWith('/dashboard')) setActiveTab('dashboard');
    else if (currentPath.startsWith('/team-dashboard')) setActiveTab('team-dashboard');
    else if (currentPath.startsWith('/projects')) setActiveTab('Projects');
    else if (currentPath.startsWith('/trash')) setActiveTab('Trash');
  }, [currentPath]);



  return (
    <>
    <div className='mainside'>
      <div className={sideBar ? 'sidmenu active' : 'sidmenu'}>
        <div className='mainsidelist'>
          <ul>
            <li className={`${activeTab === 'dashboard' ? 'active' : ''}`} onClick={() => navigate(`/dashboard/${userData.user.uuid}`, { state: { name:userData.user.name, email:userData.user.name, uuid:userData.user.uuid } })}>
              <MyDashBoardIcon />
              <p className='ms-3'>My Dashboard</p>
            </li>

            <li className={`${activeTab === 'team-dashboard' ? 'active' : ''}`} onClick={() => navigate(`/team-dashboard/${userData.user.group.uuid}`, { state: userData.user.group }  )}>
              <DashBoardIcon />
              <p className='ms-3'>Team Dashboard</p>
            </li>

            <li className={`${activeTab === 'Projects' ? 'active' : ''}`} onClick={() => navigate(`/projects`)}>
              <ProjectsIcons />
              <p className='ms-3'>Projects</p>
            </li>
           { trashData.list.length > 0 && 
            <li className={`${activeTab === 'Trash' ? 'active' : ''}`} onClick={() => navigate(`/trash`)}>
              <TrashIcon />
              <p className='ms-3'>Trash</p>
            </li>
            }

          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center flex-column'>
            <button className='download-meta' onClick={() => setCreatorPop(true)}>
                <img src={configData.config.logo}
                    onError={(e) => {
                        e.target.src = defaultLogo;
                        e.onerror = null;
                    }}
                    referrerPolicy={'no-referrer'}
                    alt=''
                />
                <span>
                    
                    <h6>{configData.config.name}</h6>
                    <p>Download Now</p>
                </span>
            </button>
            <p className='pwrdby'> Powered by <strong>
            <img src={SimulanisIcon}/>
            <span>Simulanis</span>
            </strong> </p>
            </div>
      </div>
    </div>
    
    <DownloadCreator creatorPop={creatorPop} setCreatorPop={setCreatorPop}/>
    
    </>
  );
};

export default Sidebar;
