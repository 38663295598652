import { createSlice } from "@reduxjs/toolkit";
const initialState={
    config:{
    },
    loading:true,
    error:true
}
const configSlice  = createSlice({
  name:"configSlice",
  initialState,
  reducers: {
    saveConfig: (state, action) => {
         state.config = action.payload.data,
      state.loading = false;
      state.error = false;
    },
    clearConfig: (state) => {
      state = initialState
    },
  },

});

export const { saveConfig, clearConfig } = configSlice.actions;
export default configSlice.reducer;