import React, {useState, useEffect} from 'react'
import { Nav, Tab } from 'react-bootstrap';
import { AiOutlineBook } from 'react-icons/ai';
import { MdBusiness } from 'react-icons/md';
import axios from "axios";
import { useLocation, useParams } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import DateRangeSelector from '../../filterDataRange/DateRangeSelector';
import DistributionChart from '../DashComponents/Charts/DistributionChart';
import TimeSpentChart from '../DashComponents/Charts/TimeSpentChart';
import GroupList from '../../GroupList';
import UserList from '../../UserList';
import Dashboard from './Dashboard';
import apiClient from '../../../apiClient';
import { TEAM_DASHBOARD } from '../../../apiClient/apiEndpoints';

const TeamDashboard = () => {
    const { groupUUID } = useParams();
    const params = useParams();
    let location = useLocation();
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const [filter, setFilter] = useState("all");
    const [search, setSearch] = useState("")
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [teamData , setTeamData] = useState({
        total: 0,
        active: 0,
        published: 1,
        group: {
            groups:0,
            users:0
        },
        totalTime : 0,
        weeklySpentTime: []
    });

    const getTeamDashboardAnalytics = async () => {
            let data = await apiClient.get(`${configData.config.API_URL}${TEAM_DASHBOARD}/${groupUUID}?filter=${filter}`);
            setTeamData({...data})           
    }

    useEffect(()=>{
        setActiveTab("Dashboard");
        if(groupUUID.length > 0){
            getTeamDashboardAnalytics();
        }
    },[groupUUID, filter])

    return ( 
    <>
      <div className="container-fluid p-0">
            <div className="col-md-12">
                <Tab.Container id="my-tabs" activeKey={activeTab} defaultActiveKey="Dashboard">
                    <div className="col-md-12">
                        <Nav className="tabsproduct updated toptab" variant="tabs">
                            <Nav.Item>
                                <Nav.Link onClick={() => setActiveTab('Dashboard')} eventKey="Dashboard">Dashboard</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => setActiveTab('Groups')} eventKey="Groups">Groups</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => setActiveTab('Users')} eventKey="Users">Users</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <div className='container-fluid admindashboard p-4 pt-2 bg-grey-main' >
                        <Tab.Content className='pt-3' >
                            <Tab.Pane eventKey="Dashboard">
                                <Dashboard teamData={teamData} filter={filter} setFilter={setFilter} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="Groups">
                                <GroupList search={search} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="Users">
                                <UserList search={search} />
                            </Tab.Pane>

                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>
        </div>
    </>
    )
}
export default TeamDashboard