import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux'
import { CloseIcon, DesktopWhite, MobileWhite, StanndaloneVRWhite } from '../assets/SvgIcons';


const DownloadCreator = ({ creatorPop, setCreatorPop}) => {
    const configData = useSelector((state) => state.configSlice);
    const body = document.body;
    if (creatorPop) {
        body.classList.add('propopup-active');
      } else {
        body.classList.remove('propopup-active');
      }

  return (
    <>
       <Modal
        centered
        show={creatorPop}
        onHide={() => setCreatorPop(false) }
        className='PopupModal platmodaltime launchplaymodal downloadlauncher'
        size='xl'
      >
        <Modal.Body className='position-relative p-5 '>
        <img className='meta-bg' src={configData.config.popup_logo}/>    
        <div className='playmodalmain'>
            <div className='playmodalright'>
                <div className="row">
                    <div className="col-md-6">
                        <div className='download-text'>
                        <h2> {configData.config.name}</h2>
                        <p>Download our application to access the content across different platforms</p>
                        </div>
                        <div className="playmodal_rightfoot">
                            <div className="diff_lanchers">
                            <>  
                            <Tooltip title="Download">
                                <a href="" aria-label="Desktop" className="true" title="click to download" data-mui-internal-clone-element="true">
                                    <DesktopWhite/>
                                    <span className='title'>
                                        <p>GET IT FOR</p>
                                        <h6>DESKTOP</h6>
                                    </span>
                                </a>
                                </Tooltip>
                                <Tooltip title="Download">
                                <a href="" aria-label="Standalone VR" className="true" title="click to download" data-mui-internal-clone-element="true">
                                        <StanndaloneVRWhite/>
                                    <span className='title'>
                                        <p>GET IT FOR</p>
                                        <h6>STANDALONE VR</h6>
                                    </span>
                                </a></Tooltip>
                                <Tooltip title="Download">
                                <a href=""  aria-label="Mobile" className="true" title="click to download" data-mui-internal-clone-element="true">
                                    <MobileWhite/>
                                    <span className='title'>
                                        <p>GET IT FOR</p>
                                        <h6>MOBILE</h6>
                                    </span>
                                </a></Tooltip>
                            </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div className='pro-detail-box'>
            <span className='close-icon' onClick={() => setCreatorPop(false)}>
                <CloseIcon/>    
            </span>   
       </div>
      </Modal.Body>
      </Modal>
    </>
  )
}

export default DownloadCreator
