import React, {useState, useEffect, useMemo } from 'react' 
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import DateRangeSelector from '../../filterDataRange/DateRangeSelector'
import UserCard from '../DashComponents/UserCard';
import TimeSpentChart from '../DashComponents/Charts/TimeSpentChart';
import DistributionChart from '../DashComponents/Charts/DistributionChart';
import ProjectList from '../Project/ProjectList';
import { recentProject } from '../../../env';
import apiClient from '../../../apiClient';
import { USER_DASHBOARD } from '../../../apiClient/apiEndpoints';

const Dashboard = () => {
    const { UUID } = useParams();
    let location = useLocation();
    const navigate = useNavigate();
    const [filter, setFilter] = useState("all");
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    
    const [data , setData] = useState({
        total: 0,
        active: 0,
        published: 1,
        timeSpent:0,
        recent: [],
        totalTime : 0,
        weeklySpentTime: []
    });

       const [userDetail , setUserDetail] = useState({
        name: "", email: "", uuid : "", avatar : null
    }); 

    useEffect(()=>{
        if("uuid" in location.state){
            setUserDetail({...location.state})
        }
    },[UUID, location]);

    const getMyDashboardDetail = async() =>{
        let data = await apiClient.get(`${configData.config.API_URL}${USER_DASHBOARD}/${UUID}/${recentProject}?filter=${filter}`);
        setData({...data});
    }

    useEffect(()=>{
        getMyDashboardDetail();
    },[filter])

    return ( 
        <>
            <div className="container-fluid bg-grey-main dashboard p-3 p-3 pt-2 pb-0">
                <div className="row pb-2">
                    <div className="col-md-6 d-flex align-items-center">
                    {(userData.user.uuid !== location.state.uuid) &&
                            <button className='backbtn cursor-pointer ps-2 mt-2' title="Back" style={{ fontSize: '16px' }} onClick={() => navigate(-1)}> <FaArrowLeftLong /> Back </button>
                        }
                    </div>
                    <div className="col-md-6 d-flex align-itmes-center">
                        <DateRangeSelector selected={filter} setSelected={setFilter} />
                    </div>
                </div>

                <UserCard userDetail={userDetail} data={data} />
                <div className="row admincharts mt-2">  
                    <div className="col-md-6 mt-2">
                        <div className="cardbox overflow-hidden">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0"> Time Spent / User Engagement</h5>
                                <p className="mb-1">
                                    Total Time : {data.totalTime}
                                </p>
                            </div>
                            <TimeSpentChart data={data}  />
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        <div className="cardbox overflow-hidden">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-1">Projects</h5>
                            </div>
                            <DistributionChart data={data} />
                        </div>
                    </div>
                </div>

               { data.recent.length > 0 &&
                <div className='row recentprojects mt-4'>
                    <h5>Recent Projects</h5>
                    <div className='container-fluid px-3'>
                     <ProjectList list={data.recent} showDashboard={true} />
                    </div>
                </div>
                }

            </div>

        </>
    )
}
export default Dashboard