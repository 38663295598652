import React, { useState } from 'react';
import InventoryModal from '../components/Pages/Modal/inventoryModal';
import { getLastElement } from '../utils';
import { useSelector } from 'react-redux';
import InventoryList from './InventoryList';
import Video1 from '../assets/videos/addddd.mp4'
import Dropdown from 'react-bootstrap/Dropdown';
import { AddIcon, AudioIcon, CloseIcon, ImageIcon, LinkIcon, MediaIcons, PDFIcon, QuestionMark, TextIcon, UploadFileIcon, VideoIcon } from '../assets/SvgIcons';

const EditorSidebar = ({ hotSpotType , setHotSpotType, handleReloadScene, roleType }) => {
  const prdouctSlice = useSelector((state) => state.prdouctSlice);
  const [inventory, setInventoryModal] = useState(false);
  const [drawer, setDrawer] =  useState(false);
  const [showDropdown, setShowDropdown] = useState(null);
  const [inventoryType, setInventoryType] = useState("ALL");
  let projectUUId = getLastElement(window.location.pathname);

  const selectHotspotType = (e) =>{
    if(e == hotSpotType ){
      setHotSpotType(null,false)
    }else{
      setHotSpotType(e, true)
    }
 }

  let  list = [];
  if(inventoryType == "DEFAULT"){
     list = prdouctSlice.defaultInventoryList;
  }else{
     list = prdouctSlice.inventoryList.length > 0 ?
            inventoryType == "ALL" ? prdouctSlice.inventoryList :
            prdouctSlice.inventoryList.filter(e => e.fileType == inventoryType)
            : [];
  }

return (
    <>
      <div className= { drawer ? "sidmenu editorsidemenu activedrawer" :  "sidmenu editorsidemenu " } > 
        <div className='maindrawer'>

          <span onClick={() =>{ if(roleType !== null && roleType !== "VIEWER"){ setDrawer(!drawer) }}}  className='active'>
            <MediaIcons/> Your Media
          </span>

          <span className={hotSpotType == "LINK" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false);selectHotspotType("LINK") }}} >
              <LinkIcon/> Link
          </span>

          <span className={hotSpotType == "IMAGE" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false) ;selectHotspotType("IMAGE") }}} >
            <ImageIcon/> Image
          </span>
          
          <span className={hotSpotType == "VIDEO" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false) ;selectHotspotType("VIDEO") }}} >
            <VideoIcon/> Video
          </span>

          <span className={hotSpotType == "TEXT" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false) ;selectHotspotType("TEXT") }}} >
            <TextIcon/> Text
          </span>

          <span className={hotSpotType == "AUDIO" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false) ;selectHotspotType("AUDIO") }}} >
            <AudioIcon/> Audio
          </span>

          <span className={hotSpotType == "PDF" ? 'active' : ""} onClick={()=>{if(roleType !== null && roleType !== "VIEWER"){ setDrawer(false) ;selectHotspotType("PDF") }}} >
            <PDFIcon/> PDF
          </span>

        </div>

    {/* Your Meddia side bar */}
        <div className='drawer'>
          {drawer ?
          <>
          <div className='drawertab'>
            <span className={inventoryType === "ALL" ? 'active' : ""} onClick={()=> setInventoryType("ALL")} > All </span>
            <span className={inventoryType === "DEFAULT" ? 'active' : ""} onClick={()=> setInventoryType("DEFAULT")} > Library </span>
            <span className={inventoryType === "ICON" ? 'active' : ""} onClick={()=> setInventoryType("ICON")} > Icons </span>
            <span className={inventoryType === "IMAGE" ? 'active' : ""} onClick={()=> setInventoryType("IMAGE")} > Images </span>
            <span className={inventoryType === "AUDIO" ? 'active' : ""} onClick={()=> setInventoryType("AUDIO")} > Audio </span>
            <span className={inventoryType === "VIDEO" ? 'active' : ""} onClick={()=> setInventoryType("VIDEO")} > Videos </span>
            <span className={inventoryType === "PDF" ? 'active' : ""} onClick={()=> setInventoryType("PDF")} > PDF </span>
          </div>

          <div className='mediadrawer'>
          {list.length > 0 ?
             <div className='inventorylist'>
                <InventoryList list={list} handleReloadScene={handleReloadScene} />
             </div>
              :
              <div className='d-flex align-items-center justify-content-center h-100'>
              <div className='projectlist-item content forupload' style={{ height: "100px", width:"150px", borderColor:"#33333350" }} onClick={()=>setInventoryModal(true)} >
                  <div className='addicon'>
                      <AddIcon/>
                  </div>
                  <p className='text-center'>Upload Media</p>
              </div>
              </div>
              }

          {list.length > 0 &&
              <button className='redstandardbtn' onClick={()=>setInventoryModal(true)} style={{ width:"100%" }} > Upload Media </button>
          }
          <InventoryModal inventory={inventory} setInventoryModal={setInventoryModal} projectUUId={projectUUId} />    
          <span className='closebtn editor' onClick={() => setDrawer(false) }> <CloseIcon/> </span>
          </div>
          </>
          : 
          null
          }
        </div>


      </div>
    
    </>
  );
};

export default EditorSidebar;