import React from 'react'


export const TagsIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="23.549" height="23.568" viewBox="0 0 23.549 23.568">
    <g id="analytics-line" transform="translate(0.32 0.445)">
      <g id="Group_4467" data-name="Group 4467" transform="translate(-0.32 -0.445)">
        <path id="Path_15563" data-name="Path 15563" d="M28.424,34.45a3.853,3.853,0,0,1-2.732,1.118,4.115,4.115,0,0,1-2.919-1.211L13.116,24.7a3.619,3.619,0,0,1-1.056-2.453v-7.3a2.939,2.939,0,0,1,.869-2.08A2.816,2.816,0,0,1,14.979,12h7.328a3.606,3.606,0,0,1,2.422,1.056l9.657,9.657a4.021,4.021,0,0,1,.093,5.682Zm4.44-10.185-9.657-9.657a1.334,1.334,0,0,0-.9-.4h-7.3a.893.893,0,0,0-.559.217.748.748,0,0,0-.217.559v7.266a1.346,1.346,0,0,0,.4.932l9.657,9.657a1.859,1.859,0,0,0,2.608.093l6.055-6.055A1.859,1.859,0,0,0,32.864,24.265Z" transform="translate(-12.06 -12)" fill="#333"/>
        <path id="Path_15564" data-name="Path 15564" d="M57.488,30.09a3.478,3.478,0,0,1-2.515-1.056h0a3.561,3.561,0,1,1,2.515,1.056Zm.994-4.564a1.425,1.425,0,0,0-1.987,0,1.405,1.405,0,1,0,1.987,1.987A1.445,1.445,0,0,0,58.482,25.526Z" transform="translate(-50.533 -19.533)" fill="#333"/>
      </g>
    </g>
  </svg>
}

export const CopyIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
</svg>
}

export const ShareIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"/>
</svg>
}

export const DashboardIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="23.339" height="23.339" viewBox="0 0 23.339 23.339">
    <g id="dashboard" transform="translate(0.15 0.15)">
      <path id="Path_8284" data-name="Path 8284" d="M24,21h1.512v3.78H24Z" transform="translate(-8.096 -7.335)" fill="#333" stroke="#333" strokeWidth="0.3"/>
      <path id="Path_8285" data-name="Path 8285" d="M20,16h1.512v7.56H20Z" transform="translate(-6.987 -6.049)" fill="#333" stroke="#333" strokeWidth="0.3"/>
      <path id="Path_8286" data-name="Path 8286" d="M9.78,23.56A3.784,3.784,0,0,1,6,19.78H7.512A2.268,2.268,0,1,0,9.78,17.512V16a3.78,3.78,0,0,1,0,7.56Z" transform="translate(-2.976 -6.049)" fill="#333" stroke="#333" strokeWidth="0.3"/>
      <path id="Path_8287" data-name="Path 8287" d="M23.394,2H3.646A1.647,1.647,0,0,0,2,3.646V23.394a1.647,1.647,0,0,0,1.646,1.646H23.394a1.648,1.648,0,0,0,1.646-1.646V3.646A1.647,1.647,0,0,0,23.394,2Zm0,7.406H11.874V3.646h11.52ZM10.228,3.646v5.76H3.646V3.646ZM3.646,23.394V11.051H23.394l0,12.343Z" transform="translate(-2 -2)" fill="#333" stroke="#333" strokeWidth="0.3"/>
    </g>
  </svg>
}

export const RightArrow = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
}

export const LeftArrow = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg>
}

export const PlusIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
</svg>
}

export const EditIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_3141" data-name="Group 3141" transform="translate(-24.2 -24.2)">
    <path id="Path_14272" data-name="Path 14272" d="M29.781,48.2H42.619A5.6,5.6,0,0,0,48.2,42.619h0V38.991a.93.93,0,0,0-1.86,0v3.628a3.732,3.732,0,0,1-3.721,3.721H29.781a3.732,3.732,0,0,1-3.721-3.721V29.781a3.732,3.732,0,0,1,3.721-3.721h3.628a.93.93,0,0,0,0-1.86H29.781A5.6,5.6,0,0,0,24.2,29.781V42.619A5.6,5.6,0,0,0,29.781,48.2Z" fill="#333"/>
    <path id="Path_14273" data-name="Path 14273" d="M50.482,25.677l-9.488,9.488a.9.9,0,0,0-.233.419l-1.3,4.651A.93.93,0,0,0,40.11,41.4a.951.951,0,0,0,.512,0l4.651-1.3a1.886,1.886,0,0,0,.419-.233l9.535-9.488a3.313,3.313,0,0,0,0-4.7,3.369,3.369,0,0,0-4.744,0ZM41.645,39.165l.465-1.581,1.163,1.163Zm12.186-10.14L45.04,37.816,42.994,35.77l8.791-8.791a1.462,1.462,0,0,1,2.047.14A1.478,1.478,0,0,1,53.831,29.026Z" transform="translate(-8.142 -0.267)" fill="#333"/>
  </g>
</svg>
}

export const DeleteIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20.24" height="24" viewBox="0 0 20.24 24">
  <g id="Group_4462" data-name="Group 4462" transform="translate(-108.999 -172.003)">
    <g id="trash-can-10416" transform="translate(108.999 172.003)">
      <path id="Path_156" data-name="Path 156" d="M48.776,52.948H38.467a2.343,2.343,0,0,1-2.317-2.228L35.123,32.83a.81.81,0,0,1,.212-.6.777.777,0,0,1,.571-.251h15.43a.779.779,0,0,1,.571.251.812.812,0,0,1,.213.6L51.093,50.72A2.343,2.343,0,0,1,48.776,52.948ZM36.738,33.583l.979,17.043a.759.759,0,0,0,.75.722H48.776a.758.758,0,0,0,.75-.721l.979-17.044Z" transform="translate(-33.502 -28.948)" fill="#ca353b"/>
      <path id="Path_157" data-name="Path 157" d="M37.169,33.581H18.5a.8.8,0,0,1,0-1.6H37.17a.8.8,0,0,1,0,1.6Z" transform="translate(-17.714 -28.946)" fill="#ca353b"/>
      <path id="Path_158" data-name="Path 158" d="M87.407,4.635H80.226a.792.792,0,0,1-.785-.8V2.344A2.324,2.324,0,0,1,81.74,0h4.153a2.324,2.324,0,0,1,2.3,2.344V3.835A.792.792,0,0,1,87.407,4.635Zm-6.4-1.6h5.611V2.344a.737.737,0,0,0-.729-.744H81.74a.737.737,0,0,0-.729.744v.691Z" transform="translate(-73.697)" fill="#ca353b"/>
      <path id="Path_159" data-name="Path 159" d="M156.254,88.019h-.047a.8.8,0,0,1-.737-.846l.647-11.265a.786.786,0,0,1,.829-.752.8.8,0,0,1,.737.845l-.647,11.265A.791.791,0,0,1,156.254,88.019Z" transform="translate(-142.648 -68.021)" fill="#ca353b"/>
      <path id="Path_160" data-name="Path 160" d="M75.049,88.02a.791.791,0,0,1-.782-.753L73.62,76a.785.785,0,1,1,1.566-.094l.647,11.265a.8.8,0,0,1-.737.846Z" transform="translate(-68.416 -68.022)" fill="#ca353b"/>
      <path id="Path_161" data-name="Path 161" d="M118.8,88.024a.792.792,0,0,1-.785-.8V75.959a.785.785,0,1,1,1.569,0V87.224A.792.792,0,0,1,118.8,88.024Z" transform="translate(-108.685 -68.026)" fill="#ca353b"/>
    </g>
  </g>
</svg>
}

export const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
</svg>
}

export const DownloadIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg>
}

export const UploadIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
</svg>
}

export const SimApps = () => {
  return (
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <g id="Group_2822" data-name="Group 2822" transform="translate(-1848 -23)">
          <g id="Group_2823" data-name="Group 2823" transform="translate(-2144 -1086)">
          <g id="Group_2826" data-name="Group 2826" transform="translate(-4)">
              <g id="Path_14087" data-name="Path 14087" transform="translate(4016 1129)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14088" data-name="Path 14088" transform="translate(4016 1119)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14093" data-name="Path 14093" transform="translate(4016 1109)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
          </g>
          <g id="Group_2825" data-name="Group 2825" transform="translate(-2)">
              <g id="Path_14086" data-name="Path 14086" transform="translate(4004 1129)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14089" data-name="Path 14089" transform="translate(4004 1119)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14092" data-name="Path 14092" transform="translate(4004 1109)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
          </g>
          <g id="Group_2824" data-name="Group 2824">
              <g id="Path_14085" data-name="Path 14085" transform="translate(3992 1129)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14090" data-name="Path 14090" transform="translate(3992 1119)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
              <g id="Path_14091" data-name="Path 14091" transform="translate(3992 1109)" fill="#636363">
              <path d="M 3 5.5 C 1.621500015258789 5.5 0.5 4.378499984741211 0.5 3 C 0.5 1.621500015258789 1.621500015258789 0.5 3 0.5 C 4.378499984741211 0.5 5.5 1.621500015258789 5.5 3 C 5.5 4.378499984741211 4.378499984741211 5.5 3 5.5 Z" stroke="none"/>
              <path d="M 3 1 C 1.897200107574463 1 1 1.897200107574463 1 3 C 1 4.102799892425537 1.897200107574463 5 3 5 C 4.102799892425537 5 5 4.102799892425537 5 3 C 5 1.897200107574463 4.102799892425537 1 3 1 M 3 0 C 4.65684986114502 0 6 1.34315013885498 6 3 C 6 4.65684986114502 4.65684986114502 6 3 6 C 1.34315013885498 6 0 4.65684986114502 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z" stroke="none" fill="#636363"/>
              </g>
          </g>
          </g>
      </g>
      </svg>
  )
}

export const DropDownIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
}

export const CheckIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
}