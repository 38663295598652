import React from "react";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import userimg from "../../../assets/images/user.png";
import TimeSpentChart from "./Charts/TimeSpentChart";

const UserCard = ({ userDetail, data }) => {
  let location = useLocation();
  const userData = useSelector((state) => state.userSlice);
  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <div className='datacardesign py-2'>
            <div className="row justify-content-between">
              <div className={( userData.user.uuid == userDetail.uuid) ? "col-md-5 " : "col-md-5 d-flex align-itmes-center "}>

                <div className='cardimages d-flex align-items-center'>
                {( userData.user.uuid == userDetail.uuid) ?
                  <img src={userimg} className="defaultimagesdash" alt="" />
                   :
                   userDetail.avatar ? (
                    <div className='avatarimg ps-3'>
                     <img src={userDetail.avatar} referrerPolicy={'no-referrer'} />
                    </div>
                  ) : (
                    <div className='initials-placeholder abc'>
                      {userDetail.name.split(' ').map(word => word[0]).slice(0, 2).join('')}
                    </div>
                  )
                }
                  
                  {( userData.user.uuid !== userDetail.uuid) ?
                  <div className="welcometext notwelcome ps-3">
                  <h4>{userDetail.name}</h4>
                  </div>
                      
                    :
                    <div className="welcometext ps-3">
                    <p>Welcome back !</p>
                    <h4>{userDetail.name}</h4>
                  </div> 
                  }
                </div> 
              </div>
              <div className="col-md-7">
                <div className="dashstatdata">
                  <div className="welcometext ">
                    <p>Total Projects</p>
                    <h4>{data.total}</h4>
                  </div>
                  <div className="welcometext">
                    <p>Active Projects</p>
                    <h4>{data.active}</h4>
                  </div>
                  <div className="welcometext">
                    <p>Active Engage</p>
                    <h4>{data.timeSpent} </h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
};

export default UserCard;
