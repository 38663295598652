import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import { store } from './store/store';
import './index.css';
import "./assets/CSS/custome.css"
import './assets/CSS/maincss.css'
import './assets/CSS/responsive.css'
import 'apexcharts/dist/apexcharts.css';

import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
        {/* <React.StrictMode> */}
         <App />
        {/* </React.StrictMode> */}
      </Provider>
);