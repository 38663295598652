import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SceneDashboard from './SceneDashboard';
import { saveProductDetails } from '../../../store/slice/prdouctSlice';
import EditorHeader from '../../../EditorComponents/EditorHeader';
import EditorLeftbar from '../../../EditorComponents/EditorLeftbar';
import EditorRightbar from '../../../EditorComponents/EditorRightbar';
import EditorDownbar from '../../../EditorComponents/EditorDownbar';
import HotspotConformBox from '../Modal/HotspotConfirmBox';
import AddHostpotModal from '../Modal/AddHostpotModal';
import configSlice from '../../../store/slice/configSlice';
import TimerTracker from '../../TimeTracker';
import { removeSlice } from '../../../store/slice/sceneSlice';


const EditDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  let { uuid } = params;
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const sceneSlice = useSelector((state) => state.sceneSlice).slice;
  const [inventoryType, setInventoryType] = useState("ALL")
  const [hotSpotType , setHotSpotType] = useState(null);
  const [roleType , setRoleType] = useState(null);
  const [details, setDetails] = useState({});
  const [confirmBoxModal, setConfirmBoxModal] = useState(false);
  const [hotspotSize, setHotspotSize] = useState("0.20");
  const [addHotspotModal, setAddHotspotModal] = useState(false);
  const [newHotspotData , setNewHotspotData] = useState({});
  const [selectHotspotData , setSelectHotspotData] = useState({});
  const [hotspotList,setHotspotList] = useState([]);
  const [reloadScene, setReloadScene] = useState(false)
  const [sceneView, setSceneView] = useState(false)
  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [cancelEditHotspot, setCancelEditHotspot] = useState(false)


  const getProjectDetails=async()=>{
         try {
           dispatch(removeSlice());
            let{data} = await axios.get(`${configData.config.API_URL}/api/project/details/${uuid}`,{
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                  "Content-Type": "application/json"
                }});

              setRoleType(data.data.roleType);
              dispatch(saveProductDetails({ 
                 details : data.data.project,
                 defaultInventoryList : data.data.defaultInventory
              }));

         } catch (error) {
           console.log(error);
         }
    };

    useEffect(()=>{
       if(uuid){
        getProjectDetails();
       }
    },[uuid])


    const updateSceneDetails = (e) => {
      setHotSpotType(null);
      setDetails({...e})
    }

    const updateHotspotSize = (e)=>{
        setHotspotSize(e);
    }

    const closeConfirmBoxModal = (e, isChecked) =>{
          if(e){
            isChecked ? localStorage.setItem("crtCnfrm" , "true") :  localStorage.setItem("crtCnfrm" , "false");
          }else{
            setHotSpotType(null);
          }
          setConfirmBoxModal(false);
          }
    
    const updateHotspotType =(e, v)=>{
      if("inventory" in details){
          setHotSpotType(e);
          v && localStorage.getItem("crtCnfrm") !== "true" && setConfirmBoxModal(true);
      }
     }  

  const setHostpotLinkData = (e) =>{
    setNewHotspotData({...e});
    setAddHotspotModal(true);
  }

  const handleReloadScene = () =>{
    setReloadScene(true);
    setTimeout(()=>setReloadScene(false),500);
  }
  
  const saveSceneAngle = () =>{
    setSceneView(true);
    setTimeout(()=>setSceneView(false), 500);
  }

  const handleClickFullscreen = (e) =>{
    setIsFullScreen(e);
    const element = document.documentElement;
      if(e){   // enable full screen
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
    }else{  // exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  const handleCancelEditHotspot = (e) =>{
    setCancelEditHotspot(e)
  }


  return (
     <>
            {("uuid" in sceneSlice && sceneSlice.content) && <div className='scenename'>{sceneSlice.content}</div> }
            
            <SceneDashboard roleType={roleType} details={details} hotSpotType={hotSpotType} setHostpotLinkData={(e)=> setHostpotLinkData(e)} setHotSpotNull={setHotSpotType} reloadScene={reloadScene} sceneView={sceneView} updateHotspotList={(e)=>setHotspotList(e)} selectHotspotData={selectHotspotData} hotspotSize={hotspotSize} handleClickFullscreen={(e)=>handleClickFullscreen(e)} cancelEditHotspot={cancelEditHotspot} />
            { !isFullScreen && <div>
              <EditorHeader roleType={roleType} inventoryType={inventoryType} setInventoryType={setInventoryType} setLoader={setLoader} setLoaderText={setLoaderText} />
              <EditorLeftbar roleType={roleType} hotSpotType={hotSpotType} setHotSpotType={(e,v)=>updateHotspotType(e,v)} handleReloadScene={handleReloadScene} />
              <EditorRightbar roleType={roleType} selectHotspotData={selectHotspotData} loadScene={(e) => updateSceneDetails(e)} saveSceneAngle={saveSceneAngle} handleReloadScene={handleReloadScene} hotspotList={hotspotList} setSelectHotspotData={(e)=> {updateHotspotType(null,false); setSelectHotspotData(e) }} updateHotspotSize={updateHotspotSize} handleCancelEditHotspot={handleCancelEditHotspot} />
              <HotspotConformBox roleType={roleType} confirmBoxModal={confirmBoxModal} closeConfirmBoxModal={closeConfirmBoxModal} />
              {"inventory" in details && <AddHostpotModal roleType={roleType} newHotspotData={newHotspotData} addHotspotModal={addHotspotModal} setAddHotspotModal={setAddHotspotModal} handleReloadScene={handleReloadScene} /> }       
              </div>
            }
          
          
            <TimerTracker />

            { loader && 
              <>
              <div className='loaderhere'>
                <div id="container">
                    <span class="loading-circle sp1">
                      <span class="loading-circle sp2">
                        <span class="loading-circle sp3"></span>
                      </span>
                    </span>
                    <label class="loading-title">{loaderText} ...</label>
                  </div>
                </div>
              </>
            }

     </>
  )
}

export default EditDashboard