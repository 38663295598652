import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwtEncode from "jwt-encode";
import { useDispatch } from "react-redux";
import { SideNav, SimApps } from "../assets/SvgIcons";
import { defaultLogo, applicationId, redirectUrl } from "../env";
import { removeUser } from "../store/slice/userSlice";
import { clearConfig } from "../store/slice/configSlice";
import axios from "axios";
import { Logout } from "../utils";

const Header = ({ sideBar, setSideBar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const nameParts = userData.token?.length > 0 ? userData?.user?.name?.split(" ") : "";
  const placeholder =
    nameParts?.length > 0
      ? nameParts?.length >= 2
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : `${nameParts[0][0]}`
      : "";
  const applications = useSelector((state) => state.applicationSlice).apps;
  const { user } = userData;

  const redirectApplication = (link) => {
    if (userData?.token && link) {
      const payload = { token: userData?.token };
      const token = jwtEncode(payload, "");

      if (link.includes("apps.simulanis.io")) {
        const newUrl = `${link}`;
        window.location.href = newUrl;
      } else {
        const newUrl = `${link}?referrer=${token}`;
        window.location.href = newUrl;
      }
    }
  };

  //   const logout = async() => {
  //     try {
  //             localStorage.clear();
  //             sessionStorage.clear();
  //             await axios.post(`${configData?.config?.ssoUrl}/api/auth/logout`, {}, {
  //                 headers: {
  //                     'Authorization': `Bearer ${userData.token}`,
  //                     "Content-Type": "application/json"
  //                 }
  //             });
  //             dispatch(clearConfig());
  //             dispatch(removeUser());

  //     } catch (error) {
  //         console.log(error);
  //     }finally{
  //         return window.location.href = `${redirectUrl}`;
  //     }
  // }

  return (
    <>
      <header className="mainheader">
        <div className="sidetoggle">
          <div
            className={sideBar ? "ps-1 activebar sidesvgdiv" : " sidesvgdiv ps-1"}
            onClick={() => setSideBar(!sideBar)}
            style={{ width: "25px", height: "25px" }}
          >
            <SideNav />
          </div>
          <div className="mainlogo LOGOHERE">
            <img
              src={applications.filter((e) => e.uuid === applicationId)[0]?.icon || defaultLogo}
              onError={(e) => {
                e.target.src = { defaultLogo };
                e.onerror = null;
              }}
              alt=""
            />
            <p> {applications.filter((e) => e.uuid === applicationId)[0]?.name || "XR Content Library"} </p>
          </div>
        </div>
        <div className="leftmain">
          <div className="profile">
            <div className="dropdown profileavatar">
              <p>{userData?.user?.name || null}</p>
              <button
                className="btn btn-secondary dropdown-toggle themetoggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="useravatar">
                  {userData?.user?.avatar?.length ? (
                    <img
                      src={userData?.user?.avatar}
                      alt="Profile Image"
                      title={userData?.user?.name || null}
                      className="profilepik"
                      referrerPolicy={"no-referrer"}
                    />
                  ) : (
                    <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                  )}
                </div>
              </button>
              <div className="dropdown-menu themehere popupon">
                <div className="profilemenuhead" tabIndex="0">
                  {userData?.user?.avatar?.length ? (
                    <img
                      src={userData?.user?.avatar}
                      alt="Profile Image"
                      title={userData?.user?.name || null}
                      className="profilepik"
                      referrerPolicy={"no-referrer"}
                    />
                  ) : (
                    <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                  )}
                  <div className="profilemenudata">
                    <p>{userData?.user?.name || null}</p>
                    <p>{userData?.user?.email || null}</p>
                  </div>
                </div>
                <p className="logoutbtn" onClick={() => Logout(userData.token)}>
                  Log out
                </p>
              </div>
            </div>

            <div className="dropdown profileavatar appnavigator">
              <button
                className="btn btn-secondary dropdown-toggle themetoggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <SimApps />
              </button>
              <div className="dropdown-menu themehere popupon">
                <div className="menu-long">
                  <div className={applications.length > 2 ? "menubg extraspace" : "menubg"}>
                    {applications.map((e) => {
                      if (e.uuid === applicationId) return null;
                      return (
                        <div
                          onClick={() => redirectApplication(e.channel?.origins[0]?.url)}
                          key={e.uuid}
                          className="appshere"
                        >
                          <img
                            src={e.icon || defaultLogo}
                            onError={(e) => {
                              e.target.src = { defaultLogo };
                              e.onerror = null;
                            }}
                            alt=""
                            referrerPolicy={"no-referrer"}
                          />
                          <p>{e.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
