import React, { useRef, useEffect, useState } from 'react';


const ImageViewer = ({ list , assetLink=""}) => {

  return(
        <>
        <div className='imageviewer'>
          {list.length > 0 ?
          <div id="carouselExampleIndicators" className="carousel slide">
  
          <div className="carousel-indicators">

             { list.map((e,i)=>{
                return(
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} className={i == 0 ? "active" : ""  } aria-current="true" aria-label={"Slide `${i+1}`"} ></button>
                )
              })
            }
        
          </div>
          
          <div className="carousel-inner">

          { list.map((e,i)=>{
                return(
                    <div className={i == 0 ? "carousel-item active" : "carousel-item"  } >
                      {assetLink.length > 0 ?
                      <img src={assetLink+e} className="d-block w-100" alt="..."/>
                      :
                      <img src={e.path} className="d-block w-100" alt="..."/>
                      }
                    </div>
                )
              })
            }

          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>

          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
          </div>
          :
          <p>No Imagefound</p>
          }
        </div>
        </>
    )

}

export default ImageViewer