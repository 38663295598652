import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import useWindowDimensions from '../../../../utils/useWindowDimensions';

const TimeSpentChart = ({ data }) => {
    let {width , height} = useWindowDimensions();
    
    const chartData = {
        series: [
            { name: 'Spent time(in seconds)', data: data.weeklySpentTime.map((e) => e.totalElapsedTime ) }, // Static demo data
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                },
                zoom: {
                    enabled: true,
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0,
                        },
                    },
                },
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        enabled: false,
                    },
                    columnWidth: "40%",
                    endingShape: "rounded",
                },
            },
            xaxis: {
                categories: data.weeklySpentTime.map((e) => e.dayName ), // Static demo categories
            },
            legend: {
                position: 'right',
                offsetY: 40,
            },
            fill: {
                opacity: 1,
            },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"],
                },
            },
            colors: ["#333333", "#333333"],
            yaxis: {
                labels: {
                    show: false,
                    formatter: (value, index) => {
                        return value;
                    },
                },
            },
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={width > 1500 ? 301 : 250 } />
        </div>
    );
};

export default TimeSpentChart;
