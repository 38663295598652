import React , {useEffect,useState} from "react";
import { CloseIcon, CorrectIcon, EditIcon, SideNav, SimApps } from '../../../assets/SvgIcons'
import { Link, useNavigate } from 'react-router-dom';
import configSlice from '../../../store/slice/configSlice';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import CreatorLogo from '../../../assets/images/C.png'
import { useDispatch, useSelector } from 'react-redux';
import { saveProductDetails } from "../../../store/slice/prdouctSlice";
import { getLastElement } from "../../../utils";


const ProjectPreviewHeader = ({ setLoader, setLoaderText, fileExist}) =>{

    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const prdouctSlice = useSelector((state) => state.prdouctSlice );
    let _projectUUId = getLastElement(window.location.pathname);


    const userSlice = useSelector((state) => state.userSlice );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [edit, setEdit] = useState(false);
    const [projectPublish , setProjectPublish] = useState("Publish");
    const [editable, setEditable] = useState(false)

    const handlePublishedProject = async() =>{
        try {
            setLoader(true);
            setLoaderText("Publishing")
            setProjectPublish("publish");
            await axios.get(`${configData.config.API_URL}/api/project/savePublish/${_projectUUId}`, {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                  "Content-Type": "application/json"
                }});

                setLoader(false);
                setLoaderText(null)
                setProjectPublish("published");
                setTimeout(()=>{
                    window.open(`/view/project/${_projectUUId}`)
                },500)
        } catch (error) {
            console.log(error.message);
        }finally{
            setTimeout(() => {
                setLoader(false);
            }, 500);
        }
    }

    const backToEditor = () =>{
         navigate(`/projects/edit/${_projectUUId}`)
         window.location.reload();
    }

    return(
        <>
        <header className='editorsheader'>
                <div className='d-flex align-items-center justify-content-between' style={{ width:"55%" }}>
                    <div className='sidetoggle'>
                        <div className='mainlogo LOGOHERE'>
                            <img src={CreatorLogo} alt='' />
                        </div>
                    </div>
                    <div className='project_title'>
                        <div className='namehere'>
                            <span>You’re now in Preview mode</span>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-end' style={{ width:"40%" }}>
                {fileExist &&
                    <div className='button_group' >
                        <button className='redstandardbtn withoutbg me-2' onClick={backToEditor} style={{ color:"#ca373c" }} > Back to editor </button>
                        <button className='redstandardbtn me-2' onClick={handlePublishedProject}> Publish </button>
                    </div>
                }
                 
                </div>

            </header>
            
            { edit &&
            <div className='forinputsave' onClick={()=>{setName(prdouctSlice.details.name || ""); setEdit(!edit)}}>
            </div> }
        </>
    )

}

export default ProjectPreviewHeader;