import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const HotspotConformBox = ({confirmBoxModal , closeConfirmBoxModal}) =>{

    const [isChecked, setChecked] = useState(false);

    const close = (e,i) =>{
        closeConfirmBoxModal(e,i);
        setChecked(false);
    }

    return(
        <>
                    <Modal size='md' centered show={confirmBoxModal} onHide={()=>close(false , isChecked)}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group pt-4 m-0">
                                    <p className="popupheading delete p-0 m-0 mb-3" style={{fontWeight:"500" , fontSize:"20px", color:"#000"}}> Choose location and click to add new Hotspot </p>
                                    <p className='d-flex align-items-center justify-content-center'>
                                      <input 
                                        className="form-check-input standardcheckbox mt-0 pt-0 me-2" 
                                        type="checkbox" 
                                        value="" 
                                        checked={isChecked}
                                        onChange={()=> setChecked(!isChecked)}
                                        />
                                    <p className='m-0'>  Don't show this message again. </p></p>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                                       <button className="createbtn cancelbtn"onClick={()=>close(true, isChecked)} >OK</button>
                                    </div>
                                    
                                 </div>
                            </div>
                        </div>
                    </Modal.Body>
            </Modal>
        </>
    )

}

export default HotspotConformBox