import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteIcon } from '../assets/SvgIcons';
import ConfirmBox from '../components/Pages/Modal/ConfirmBox';
import AudioBG from '../assets/images/Audioskeleton.png'
import VideoBG from '../assets/images/Videoskeleton.png'
import PdfBG from '../assets/images/PDFskeleton.png'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { saveProductDetails } from '../store/slice/prdouctSlice';

const InventoryList = ({ list, handleReloadScene }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [deleteID, setDeleteID] = useState(null);
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const prdouctSlice = useSelector((state) => state.prdouctSlice);

  const DeleteInventory = (uuid) => {
      setDeleteID(uuid);
      setShow(true);
  }

  const closeModal = () =>{
    setDeleteID(null);
    setShow(false);
  }

  const handleDelete = async() =>{
    try {
         
      let{ data } = await axios.get(`${configData.config.API_URL}/api/inventory/delete/${deleteID}/${prdouctSlice.details.uuid}`,{
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json"
        }});

        dispatch(saveProductDetails({ 
          details : data.data.project,
          defaultInventoryList : data.data.defaultInventory
          }));

        handleReloadScene();

    } catch (error) {
        console.log(error);
    }finally{
      closeModal();
    }
}

  return (
    <>
    { list.map((e,i)=>{
        const fileExtension = e.path.substring(e.path.lastIndexOf('.') + 1);
        return(
          <div className='importedfile' key={i} >
                {fileExtension === 'pdf' && <img src={PdfBG} alt="PDF" />}
                {fileExtension === 'mp3' && <img src={AudioBG} alt="MP3" />}
                {fileExtension === 'mp4' && <img src={VideoBG} alt="MP4" />}
                {['pdf', 'mp3', 'mp4'].indexOf(fileExtension) === -1 && (
                    <img src={e.path} alt="Default" />
                )}
              <div className='info'>
                <p>{e.name}</p>
                    {e.type == "PRIVATE" &&
                      <span onClick={()=>DeleteInventory(e.uuid)}>
                        <DeleteIcon/>
                      </span>
                    }
              </div>
          </div>
        )
      }) }

     <ConfirmBox title={"Delete Inventory"} message={"Are you sure you want to delete inventory?? This will delete all its related views and hotspots."} show={show} setShow={closeModal} handleConfirm={handleDelete} />

    </>
  );
};

export default InventoryList;
