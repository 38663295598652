import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { HotspotSceneList, HotspotImageList, HotspotDataList } from "./HotspotData";
import axios from "axios";
import { CloseIcon } from "../../../assets/SvgIcons";
import { debounce } from "../../../utils";

const AddHostpotModal = ({newHotspotData, addHotspotModal , setAddHotspotModal, handleReloadScene}) =>{
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const prdouctSlice = useSelector((state) => state.prdouctSlice);
    const [title, setTitle] = useState("");
    const [iconList, setIconList] = useState([]);
    const [linkType , setlinkType] = useState(null);
    const [selectedScene, setSelectedScene] = useState("");
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [selectedIconPath, setSelectedPath] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [selectedData, setSelectedData] = useState({
        path:null,
        uuid:null
    });
    const [text, setText] = useState(null);
    const [titleError, setTitleError] = useState("");
    const [iconError, setIconError] = useState("");
    const [linkSceneError, setLinkSceneError] = useState("");
    const [imageSceneError, setImageSceneError] = useState("");
    const [error, setError] = useState("");
    const [saveBtn, setSaveBtn] = useState("Add");


    useEffect(()=>{
        if("hotSpotType" in newHotspotData){
            let iconList = prdouctSlice.defaultInventoryList;
            let allPNGimage = prdouctSlice.inventoryList.filter((e) => e.fileType == "IMAGE" && e.name.endsWith(".png") );
            setIconList([...iconList, ...allPNGimage]);
            setlinkType(newHotspotData.hotSpotType);
        }
        },[newHotspotData]);


      const checkValidation = () =>{
        let notHaveError = true;

        if(!title){
            setTitleError("Title is required.");
            notHaveError = false;
        }

        if(!selectedIcon){
            setIconError("Icon is required.");
            notHaveError = false;

        };

        if(linkType == "LINK"){ 
            if(!selectedScene){
                setLinkSceneError("Please select a scene.");
                notHaveError = false;
            };
        }else if(linkType == "IMAGE"){
            if(selectedImage.length == 0){
                setImageSceneError("Select at least one image")
                notHaveError = false;
            }
        }else if(linkType == "TEXT"){
            if(!text){
                setError(`Title is required.`)
                notHaveError = false;
            }
        }else{
            if(!selectedData.path){
                setError(`Please select ${linkType}.`)
                notHaveError = false;
            }
        }

        return notHaveError
    }
    
    const getImageDimensions = async(url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = reject;
          img.src = url;
        });
      };

    const saveHotspot = async() =>{
        try {
            
        let validate = checkValidation();
        if(validate ){
           let hotspotData={};
            if(linkType == "LINK"){ 
                hotspotData[linkType] = selectedScene;
            }else if(linkType == "IMAGE"){
                hotspotData[linkType] = selectedImage;
            }else if(linkType == "TEXT"){
                hotspotData[linkType] = text
            }else {
                hotspotData[linkType] = selectedData
            }

            let {width, height} = await getImageDimensions(selectedIconPath);
            setSaveBtn("Saving");
            let _data ={
                name : title,
                inventoryId : selectedIcon,
                linkType : linkType,
                sceneUUID : newHotspotData.sceneUUID,
                hotpotView:{
                    x: newHotspotData.x,
                    y: newHotspotData.y,
                    z: newHotspotData.z,
                    scaleX: width * 0.20, 
                    scaleY: height * 0.20, 
                    scaleZ: 0,
                    hotspotData
                }
            }

            await axios.post(`${configData.config.API_URL}/api/hotspot/create` , _data ,{
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type": "application/json"
                }});
            setSaveBtn("Add");
            handleReloadScene();
            closeModal();
        }

        } catch (error) {
             console.log(error.message);
        }
        }

    const closeModal = () =>{
            setTitle("");
            setSelectedIcon(null);
            setSelectedPath(null);
            setSelectedScene("");
            setAddHotspotModal(false);
            setSelectedImage([]);
            setSelectedData({
                path:null,
                uuid:null
            });
            setText(null)
            setTitleError("");
            setIconError("");
            setLinkSceneError("");
            setImageSceneError("");
            setError("");
       }



    const debounceAddHotspot = debounce(saveHotspot, 500);

    return(
        <>
            <Modal size='xl' centered show={addHotspotModal} onHide={closeModal}>
                <Modal.Header  className='modalheader'>
                    <Modal.Title> 
                                { linkType &&
                                        linkType == "LINK" ?  "Add Link" :
                                        linkType == "IMAGE" ? "Add Image" :
                                        linkType == "VIDEO" ? "Add Video"  : 
                                        linkType == "TEXT" ?  "Add Text" :
                                         linkType == "AUDIO" ? "Add Audio" :
                                         linkType == "PDF"  ?  "Add PDF"  : 
                                         null
                                    }

                         </Modal.Title>
                    <span className='closebtn' onClick={closeModal}><CloseIcon/></span>
                </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 standardinput">
                               <label>
                               <span className='d-flex align-tems-center gap-2'> 
                                 Scene Name
                                 <p className="error">{titleError}</p>
                               </span>
                                
                                <input type="text" value={title} onChange={(e)=>setTitle(e.target.value) } /> 
                                </label>
                            </div>
                            <div className="col-md-12 standardinput">
                               
                                    <>
                                 <span className='d-flex align-tems-center gap-2'> 
                                    Select Icon
                                   <p className="error">{iconError}</p>
                                 </span>
                                 
                                 <div className="popupheading delete p-0 m-0 mb-3 d-flex flex-wrap gap-2" style={{fontWeight:"500" , fontSize:"20px", color:"#000"}}>
                                    {iconList.length > 0 &&
                                        iconList.map((e,i)=>{
                                            return(
                                                <div key={i} className= { e.id == selectedIcon ? 'projectlist-item selected' : 'projectlist-item'}>
                                                    <label>
                                                    <input
                                                    type="radio"
                                                    name="checkboxGroup"
                                                    value={e.name}
                                                    checked={e.id == selectedIcon}
                                                    onChange={()=>{setSelectedPath(e.path); setSelectedIcon(e.id)}}
                                                    />
                                                    <img src={e.path}/>
                                                    </label>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                                    </svg>
                                                </div>
                                                )
                                        })}
                                 </div>
                                    
                                    </>

                                    {linkType ?
                                        (linkType == "LINK") ?
                                         <HotspotSceneList setSelectedScene={setSelectedScene} linkSceneError={linkSceneError} selectedScene={selectedScene} />  :
                                        (linkType == "IMAGE") ? 
                                        <HotspotImageList selectedImage={selectedImage} setSelectedImage={setSelectedImage} imageSceneError={imageSceneError} />  :
                                        (linkType == "VIDEO" || linkType == "TEXT" || linkType == "AUDIO" || linkType == "PDF" ) ? 
                                        <HotspotDataList selectedData={selectedData} setSelectedData={setSelectedData} linkType={linkType} error={error} text={text} setText={setText} /> 
                                          : null : null  
                                    }
                                   
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end mt-2 mb-2 footerbtn">
                                    <button className="createbtn cancelbtn" onClick={closeModal} >Cancel</button>
                                    {/* <button className="createbtn cancelbtn"onClick={saveHotspot} >Add</button> */}
                                    <button className="createbtn cancelbtn"  onClick={saveBtn === "Add"? debounceAddHotspot  : null} > {saveBtn} </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
            </Modal>
        </>
    )

}


export default AddHostpotModal