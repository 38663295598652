import React, {useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Tab, Nav } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CopyIcon, DeleteIcon, EditIcon, SearchesCIcon, PreviewIcon, LiveIcon, CancelIcon } from '../../../assets/SvgIcons';
import CreateProjectModal from '../Modal/projectModal';
import ConfirmBox from '../Modal/ConfirmBox';
import NoDataFound from '../../../NoDataFound';
import {defaultLogo} from "../../../env.js";
import apiClient from '../../../apiClient/index.js';
import { CLONE_PROJECT, MOVE_TO_TRASH } from '../../../apiClient/apiEndpoints.js';
import ShareProject from '../Modal/ShareProject.js';
import { ShareIcon } from '../../../iconshere.js';


const ProjectList = ({ list, getProjectList, showDashboard, trashProjectList, usersList }) => {

    const navigate = useNavigate();
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const prdouctSlice = useSelector((state) => state.prdouctSlice);
    const [createModal, setCreateModal] = useState(false);
    const closeCreateModal = () => setCreateModal(false);
    const openCreateModal = () => setCreateModal(true);
    const [search, setSearch] = useState("");
    const [show, setShow] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [actionProjectId , setActionProjectId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modelType, setModelType] = useState(null);
    const [title, setTitle] = useState(null);
    const [message, setMessage] = useState(null);
    const [shareProject, setShareProject] = useState({
        id: null,
        name: null
    });

    const confirmMoveToTrash = (e, type) => {
        setActionProjectId(e);
        setShow(true);
        setModelType(type);
        if(type === "TRASH"){
            setTitle("Move to trash");
            setMessage("Do you really want to move this project to the trash?");
        }else if(type === "CLONE"){
            setTitle("Make a clone");
            setMessage("Do you really want to clone this project?");
        }
    }

    const cloneProject = async() =>{
         await apiClient.get(`${configData.config.API_URL}${CLONE_PROJECT}/${actionProjectId}`);
         closeModal();
         getProjectList();
         trashProjectList();
    }

    const handleMoveToTrash = async() =>{
            await apiClient.get(`${configData.config.API_URL}${MOVE_TO_TRASH}/${actionProjectId}`);
            closeModal();
            getProjectList();
            trashProjectList();
    }

    const handleConfirm = async() =>{
        setLoading(true)
        if(modelType === "TRASH"){
            await handleMoveToTrash();
        }else if(modelType === "CLONE"){
            await cloneProject();
        }
        setLoading(false)
    }

    const closeModal = () => {
        setShow(false);
        setActionProjectId(null);
        setModelType(null);
        setTitle(null);
        setMessage(null);
    }

     const handleEnableShareProject =(data)=>{
        setShareProject({
            id: data.uuid,
            name: data.name
        })
        setShowShareModal(true)

     }


  return (
     <>
        {!showDashboard &&
                <div className="row pt-3 mb-3 ps-0">
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                            <div className="position-relative">
                                <SearchesCIcon />
                                {search.length > 0 &&
                                    <span className='searchiconcancel' onClick={() => setSearch("")} >
                                        <CancelIcon />
                                    </span>
                                }
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    name="searchName"
                                    className="form-control"
                                    placeholder="Search"
                                    autoComplete="off"
                                />

                            </div>
                        </form>
                    </div>
                    <div className='col-md-6 d-flex align-items-center justify-content-end'>
                        <button className='redstandardbtn' onClick={openCreateModal}>New Project</button>
                    </div>
                </div>
            }

            <div className='row pt-2 pt-1' >
                {list.length > 0 ?
                    list.map((e, index) => {
                        let roleType = e.allocations[0].type
                        if (search.length && !(e.name.toLowerCase().includes(search))) return null;
                        return (
                            <div className='col-xxl-2 col-xl-2  col-lg-3 col-md-4 col-sm-6 col-12 mb-2 p-2' key={index} >
                                <div className='projectbody'>
                                    <div className='projectthumb'>
                                        {Array.isArray(e.scenes) && e.scenes.length > 0 ?
                                            <img src={e.scenes[0].inventory.path} onError={(e) => {
                                                e.target.src= {defaultLogo};
                                            }} />
                                            :
                                            <img src= {defaultLogo} />
                                        }
                                        <div className='withedit'>
                                            <Link to={`/projects/edit/${e.uuid}`} >
                                                <EditIcon />
                                                Edit
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='projectinfo'>
                                        <div className='projectname'>
                                            <Tooltip title={e.name}>
                                                <p>{e.name}</p>
                                            </Tooltip>

                                            {(!showDashboard && roleType !== null && roleType !== "VIEWER") &&
                                              <div className='userdrop'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic">
                                                            <div className="options">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="sidebartopbar modulelistoptions">
                                                            <div className="listoptionsmodule">
                                                                {roleType === "OWNER" &&<span onClick={() => confirmMoveToTrash(e.uuid, "TRASH")} >
                                                                    <DeleteIcon />
                                                                    Move to trash
                                                                </span>}
                                                                {e.preview &&
                                                                    <span onClick={() => window.open(`/preview/project/${e.uuid}`)} >
                                                                        <PreviewIcon />
                                                                        Preview
                                                                    </span>
                                                                }
                                                                {e.published &&
                                                                    <span onClick={() => window.open(`/view/project/${e.uuid}`)}>
                                                                        <LiveIcon />
                                                                        Live View
                                                                    </span>
                                                                }
                                                                <span onClick={()=> handleEnableShareProject(e)}> <ShareIcon/> Share Project </span>
                                                                {roleType === "OWNER" &&
                                                                  <span onClick={() => confirmMoveToTrash(e.uuid, "CLONE")}> <CopyIcon/> Clone </span>
                                                                }
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                              </div>
                                            }
                                        </div>

                        </div>
                    </div>
                </div>
                )
               })
               :
               <NoDataFound heading={"No Projects Found "}/>
        }
        </div>

        {showShareModal && <ShareProject showShareModal={showShareModal} closeShareProjectModal={()=>setShowShareModal(false)} usersList={usersList} shareProject={shareProject} /> }
        <CreateProjectModal createModal={createModal} closeCreateModal={closeCreateModal} getProjectList={getProjectList} />
        <ConfirmBox title={title} message={message} show={show} setShow={closeModal} handleConfirm={handleConfirm} />

     </>
  )
}

export default ProjectList