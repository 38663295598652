import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import PdfIcon from "../../../assets/images/pdf.png"
import VideoBG from '../../../assets/images/Videoskeleton.png'
import UploadingGif from "../../../assets/images/uploading.gif"
import MusicIcon from "../../../assets/images/music.png"
import { AddIcon } from '../../../assets/SvgIcons';
import InventoryModal from './inventoryModal';

export const HotspotSceneList = ({ setSelectedScene , linkSceneError, selectedScene }) =>{
  const prdouctSlice = useSelector((state) => state.prdouctSlice );
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const list = prdouctSlice.sceneList;

  useEffect(()=>{
    const result = list.map((item,i)=>{
      return({
          label: item.name,
          value: item.uuid
      })
    });
    setOptions(result);
  },[list]);

  useEffect(()=>{
    if(selectedScene){
      const result = options.filter((e) => e.value == selectedScene)[0];
      setSelectedOption(result);
    }
  },[options, selectedScene]);

  const handleSelectChange = (values) => {
        //  setSelectedOption(values);
         setSelectedScene(values.value)
        };
        
  return(
      <>
      <div className="col-md-12 standardinput">    
      <>
        <span className='d-flex align-tems-center gap-2'> 
           Select Scene  
          <p className="error">{linkSceneError}</p>
        </span>

       <Select
          value={selectedOption}
          options={options}
          onChange={handleSelectChange}
       />
       </>
     
       </div>
      </>
  )
}

export const HotspotImageList = ({ selectedImage, setSelectedImage, imageSceneError }) =>{
  const prdouctSlice = useSelector((state) => state.prdouctSlice );
  const [inventory, setInventoryModal] = useState(false);
    


    const selectScene = (e)=>{
        let allImages = [...selectedImage];
        const index = allImages.findIndex(item => item.uuid === e.uuid);
        if (index !== -1) {
          allImages.splice(index, 1);
        } else {
          allImages.push({
            uuid:e.uuid,
            path:e.path
          });
        }
       setSelectedImage(allImages);
   }
    const list = prdouctSlice.inventoryList.length > 0 ? 
                prdouctSlice.inventoryList.filter(e => e.fileType == "IMAGE") 
                :[];
    return(
        <>
         <div className="col-md-12 standardinput">    
            <>
            <span className='d-flex align-tems-center gap-2'>
              Select Image
              <p className="error">{imageSceneError}</p>
            </span>

            <div className="popupheading delete p-0 m-0 mb-0  d-flex flex-wrap gap-2 gallery" style={{fontWeight:"500" , fontSize:"20px", color:"#000"}}>
             
              <div onClick={()=>setInventoryModal(true)} className={'projectlist-item content forupload newscene'}>
                <div className='addicon'>
                  <AddIcon/>
                </div>
                <p>Upload Media</p>
              </div>

             
             {list.length > 0 ? list.map((e, i) => {
                  return(
                  <div key={i} className= { selectedImage.some(item => item.uuid === e.uuid) ? 'projectlist-item content selected' : 'projectlist-item content'}>
                    <label>
                      <input
                      type="checkbox"
                      name="checkboxGroup"
                      value={e.name}
                      checked={selectedImage.some(item => item.uuid === e.uuid)}
                      onChange={()=>selectScene(e)}
                    />
                    <img src={e.path}/>
                    </label>
                    <p>{e.name}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                   </div>
                   ) 
                  })
                : null
              }

            </div>
            </>
           
         </div>
         <InventoryModal inventory={inventory} setInventoryModal={setInventoryModal} projectUUId={prdouctSlice.details.uuid} />

        </>
    )
}

export const HotspotDataList = ({ selectedData, setSelectedData, linkType, error, text , setText }) =>{
    const prdouctSlice = useSelector((state) => state.prdouctSlice );
    const [inventory, setInventoryModal] = useState(false);


    const list = prdouctSlice.inventoryList.length > 0 ? 
                prdouctSlice.inventoryList.filter(e => e.fileType == linkType) 
                :[];

    return(
        <>
        <div className="col-md-12 standardinput">    
         
         {
          linkType == "TEXT" ?
          <>  
          <label>
            <span className='d-flex align-tems-center gap-2'> 
            Title 
           <p className="error"> {error} </p>
           </span>
             <input type="text" value={text} onChange={(e)=>setText(e.target.value)} />
             </label>
          </>
          :
          <>
          <span className='d-flex align-tems-center gap-2'> 
          { linkType == "VIDEO" ? "Select Video" :  linkType == "AUDIO" ?  "Select Audio"  : linkType == "PDF" ? "Select PDF" : null}  
          <p className="error"> {error} </p>
          </span>
         
          <div className="popupheading delete p-0 m-0 mb-3 d-flex flex-wrap gap-2" style={{fontWeight:"500" , fontSize:"20px", color:"#000"}}>
              <div onClick={()=>setInventoryModal(true)} className={'projectlist-item content forupload newscene'}>
                <div className='addicon'>
                  <AddIcon/>
                </div>
                <p>Upload Media</p>
              </div>
            
            {list.length > 0 ? list.map((e, i) => {
                return(
                  <div key={i} className= { e.uuid == selectedData.uuid ? 'projectlist-item content selected' : 'projectlist-item content'}>
               <label>
                    <input
                      type="radio"
                      name="checkboxGroup"
                      value={e.name}
                      checked={e.uuid == selectedData.uuid}
                      onChange={()=>setSelectedData({path:e.path,uuid:e.uuid})}
                    />
                    {
                    linkType == "VIDEO" ?
                      <video width="98" height="80" controls={false} >
                        <source src={e.path} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      // <img src={VideoBG}/>
                    :
                    linkType == "AUDIO" ?
                     <img src={MusicIcon}/>
                    :
                    linkType == "PDF" ?
                       <img src={PdfIcon}/>
                    :
                    null
                    }
                  <p>{e.name}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
               </label>
                  </div>
                  ) 
                })
              : null
            }
          <InventoryModal inventory={inventory} setInventoryModal={setInventoryModal} projectUUId={prdouctSlice.details.uuid} />
          </div>
         
          </>
            }
            </div>




        </>
    )
}