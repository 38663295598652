import React, {useState} from 'react'
import { Tab, Nav } from 'react-bootstrap';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProjectList from './ProjectList.js';
import { saveTrash } from '../../../store/slice/trashSlice.js';
import apiClient from '../../../apiClient/index.js';
import { GROUP_USER_LIST, PROJECT_LIST, TRASH_PROJECT_LIST } from '../../../apiClient/apiEndpoints.js';


const Projects = () => {
    const dispatch = useDispatch();
    const [tabActiveKey, setTabActiveKey] = useState("ongoing")
    const [list, setList] = useState([]);
    const [yetToStart, setYetToStart] = useState([]);
    const [published, setPublished] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [shared, setShared] = useState([]);
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);

    const handleChangeActiveKey = (selectedTab) => {
        setTabActiveKey(selectedTab)
    }
    
    const getProjectList= async()=>{
        let data= await apiClient.get(`${configData.config.API_URL}${PROJECT_LIST}`);
        setList(data.list);
        setYetToStart(data.yetToStart);
        setPublished(data.published);
        setPublished(data.published);
        setShared(data.shared);
    };

    const trashProjectList= async()=>{
            let data = await apiClient.get(`${configData.config.API_URL}${TRASH_PROJECT_LIST}`);
            dispatch(saveTrash(data));
    };

    const groupUsersList = async() =>{
        try {
            let data = await apiClient.get(`${configData.config.UNO_URL}${GROUP_USER_LIST}/${userData.user.group.uuid}`);
            setUsersList(data);
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(()=>{
        trashProjectList();
        getProjectList();
        groupUsersList()
    },[])


    return (
    <>
       <Tab.Container id="my-tabs" activeKey={tabActiveKey} onSelect={handleChangeActiveKey} >
                <Nav className="tabsproduct updated toptab cc" variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="ongoing">Ongoing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tostart">Yet To Start</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="completed">Published</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="shared">Shared</Nav.Link>
                    </Nav.Item>
                </Nav>

                <div className='container-fluid p-4 pt-5 bg-grey-main'>
                    <Tab.Content>
                        <Tab.Pane eventKey="ongoing">
                             <ProjectList list={list} trashProjectList={trashProjectList} getProjectList={getProjectList} showDashboard={false} usersList={usersList}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tostart">
                             <ProjectList list={yetToStart} trashProjectList={trashProjectList} getProjectList={getProjectList} showDashboard={false} usersList={usersList}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="completed">
                             <ProjectList list={published} trashProjectList={trashProjectList} getProjectList={getProjectList} showDashboard={false} usersList={usersList}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="shared">
                             <ProjectList list={shared} trashProjectList={trashProjectList} getProjectList={getProjectList} showDashboard={false} usersList={usersList}/>
                        </Tab.Pane>
                    </Tab.Content>
                </div>

       </Tab.Container>

    </>
    )
}
export default Projects