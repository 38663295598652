import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import jwtEncode from "jwt-encode";
import { jwtDecode } from "jwt-decode";
import './App.css';
import Loader from "./Loader"
import { Logout, redirectSSO } from './utils';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import AppRouter from './components/AppRouter';
import { saveConfig } from './store/slice/configSlice';
import { saveUserData } from './store/slice/userSlice';
import { myApplicationList } from './store/slice/applicationSlice';
import ProjectPublished from './components/Pages/Project-Published/ProjectPublished';
import ProjectPreview from './components/Pages/Project-Preview/ProjectPreview';
import {  UNO_URL, applicationId, redirectUrl  } from './env';

const Common = ({ sideBar, setSideBar, setHide }) => {
  const location = useLocation();
  const viewEditor = location.pathname.startsWith("/projects/edit/");
  useEffect(() => {
    viewEditor ? setHide(true) : setHide(false);
  }, [viewEditor, setHide]);

  return !viewEditor ? (
    <>
      <Header sideBar={sideBar} setSideBar={setSideBar} />
      <Sidebar sideBar={sideBar} />
    </>
  ) : null;
};

const App = () => {
  const userData = useSelector((state) => state.userSlice);
  const [sideBar, setSideBar] = useState(false);
  const [hide, setHide] = useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fetchApplication = async (token) => {
    try {
    var { data } = await axios.get(`${UNO_URL}/api/application/mine`,
        {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        }
    );
    dispatch(myApplicationList(data.data));
    } catch (error) {
    console.log(error);
    }
    };

  const getUserDetail = async (config) => {
             
        const urlParams = new URLSearchParams(window.location.search);
        const signOnUrl = config.redirectUrl || redirectUrl;
        const _tokenParam = urlParams.get("referrer");
        let token;
        if (_tokenParam) {
            const decoded = jwtDecode(_tokenParam);
            localStorage.setItem("token", decoded.token);
            token = decoded.token;
        } else if (localStorage.getItem("token")) {
            token = localStorage.getItem("token");
        } else {
            const currentURL = window.location.href;
            // const baseUrl = new URL(currentURL).origin;
            const baseUrl = new URL(currentURL).href;
            const payload = {
                origin: baseUrl,
            };
            const referrer = jwtEncode(payload, "");
            return (window.location.href = `${signOnUrl}?referrer=${referrer}`);
        }
        try {
            var response = await axios.get(`${config?.ssoUrl}/api/user/me`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.status === 200) {
                localStorage.setItem("token", response?.data?.data?.token);
                const data = {
                    token: response?.data?.data?.token,
                    user: response?.data?.data?.user,
                };
                fetchApplication(response?.data?.data?.token);
                dispatch(saveUserData(data));
                setLoading(false);
            }
        } catch (error) {
            await Logout(token);
        }
    };

  const getConfiguration = async () => {
        await axios.get(`${UNO_URL}/api/application/configuration/${applicationId}`)
            .then((response) => {
                const data = {...response.data.data.data}
                // data["API_URL"]= "http://localhost:3333"
                // data["SSO_URL"]= "https://sso.simulanis.io"
                // data["UNO_URL"]= "https://api.simulanis.io"

                dispatch(saveConfig({ data }));
                getUserDetail(data)
            })
            .catch((err) => {
                console.log(err);
                redirectSSO();
            });
    };

  useEffect(() => {
    if( !window.location.pathname.startsWith('/view/project/') ){
      getConfiguration();
    }
  }, []);

  // if (loading) return <div><Loader /></div>
  return (
    <Router>
      
      <Routes>
        <Route
          path='/view/project/:uuid'
          element={<ProjectPublished /> }
        />
        
        <Route
          path='*'
          element={
            loading ? (
              <div><Loader /></div>
              ) : (
               <>
                { window.location.pathname.startsWith('/preview/project/') ?
                  <Routes>
                      <Route 
                        path='/preview/project/:uuid' 
                        element={<ProjectPreview /> }
                      />
                  </Routes>
                  :
                  <>
                  <Common sideBar={sideBar} setSideBar={setSideBar} setHide={setHide} />
                  <div className={hide ? 'parentofall' : `main-content parentofall ${sideBar ? 'sideactive' : ''}`}>
                    <AppRouter />
                  </div>
                  </>
                }
              </>
            )
          }
        />

      </Routes>
    </Router>
  );
};

export default App;
