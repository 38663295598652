import { configureStore } from "@reduxjs/toolkit";
import applicationSlice from "./slice/applicationSlice";
import configSlice from "./slice/configSlice";
import prdouctSlice from "./slice/prdouctSlice";
import userSlice from "./slice/userSlice";
import sceneSlice from "./slice/sceneSlice";
import trashSlice from "./slice/trashSlice";

export const store = configureStore({
    reducer:{
        configSlice,
        userSlice,
        applicationSlice,
        prdouctSlice,
        sceneSlice,
        trashSlice
    }
});