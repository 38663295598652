import { createSlice } from "@reduxjs/toolkit";

const initialState={
    list:[]
}

const trashSlice  = createSlice({
    name:"trashSlice",
    initialState,
    reducers: {
      saveTrash: (state, action) => {
        state.list = action.payload;
      }
    }
  });

export const { saveTrash } = trashSlice.actions;
export default trashSlice.reducer;