import React from 'react';
import Lottie from 'react-lottie';
import noDataFoundOne from './assets/noDataFound.json'; // Corrected the import path

const NoDataFound = ({ heading, nodataText }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: noDataFoundOne,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <div className="no-data-found">
            <div className="text-data"> {/* Corrected class name to "text-data" */}
                <Lottie options={defaultOptions} height={130} width={130} />
                <h5 style={{ marginTop: '-10px' }}>{heading}</h5>
                <p>{nodataText}</p> 
            </div>
        </div>
    );
}

export default NoDataFound;