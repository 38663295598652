import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Progress from 'react-progressbar';
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from 'react-redux';
import { allowedFileTypes, getFileType } from '../../../utils';
import { saveInventory } from '../../../store/slice/prdouctSlice';
import { CloseIcon, UploadFileIcon, UploadIcon } from '../../../assets/SvgIcons';

const InventoryModal = ({ inventory, setInventoryModal, projectUUId }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dropAreaRef = useRef(null);
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);

  const close = () => {
    setInventoryModal(false);
    setUploadedFiles([]);
  };

  useEffect(() => {
    setUploadedFiles([]);
  }, [inventory]);

  const handleFileChange = (files) => {
    const updatedFiles = Array.from(files).map((file, index) => ({
      file,
      progress: 0,
      uploading:false,
      preview: URL.createObjectURL(file),
    }));

    setUploadedFiles([...uploadedFiles, ...updatedFiles]);
    updatedFiles.forEach((file) => {
      uploadFile(file.file);
    });

  };

  const handleInputChange = (event) => {
  
    const files = event.target.files;
    const validFiles = [];  
    for (let i = 0; i < files.length; i++) {
      const fileType = files[i].type;
      if (allowedFileTypes.includes(fileType)) {
        validFiles.push(files[i]);
      } 
    }

    if(validFiles.length > 0){
       handleFileChange(validFiles);
    }
     
  };
  
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const fileType = getFileType(file.type);
      const response = await axios.post(`${configData.config.API_URL}/api/inventory/upload/${projectUUId}/${fileType}`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            'Content-Type': 'multipart/form-data',
          },
          // onUploadProgress: (progressEvent) => {
          //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
          //   updateFileProgress(file, Math.floor(progress));
          // },
        }
      );

      updateFileProgress(file , response?.data?.url, fileType);

    } catch (error) {
      console.error(error.message);
    }
  };

  const updateFileProgress = (file, path, fileType) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((upload) => {
        if (upload.file === file) {
          return { 
               ...upload, 
               originalName: file.name,
               path,
               fileType,
               uploading:true 
              };
        }
        return upload;
      })
    );
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileChange(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    dropAreaRef.current.classList.add('drag-over');
  };

  const handleDragLeave = () => {
    dropAreaRef.current.classList.remove('drag-over');
  };

  const handleSubmit = async () => {
    try {

      let fileUrls = uploadedFiles.map((e) => ({fileType:e.fileType, originalName:e.originalName, path:e.path}));
      let _data = { data: fileUrls };
      let { data } = await axios.post(`${configData.config.API_URL}/api/inventory/save/${projectUUId}`, _data, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      dispatch(saveInventory({ inventoryList: data.data }));
      close();

    } catch (error) {
      console.log(error.message);
    }
  };

  const formatSize = (bytes) => {
    if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' bytes';
    }
  };

  const handleCancelFile = (i) =>{
    let modifiedValue = [...uploadedFiles];
    modifiedValue.splice(i,1);
    setUploadedFiles([...modifiedValue])
  }

  return (
    <>
      <Modal
        show={inventory}
        onHide={close}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="popupheader delete pt-4">
           Upload Media
        </Modal.Header>
        <Modal.Body>
        <div
          ref={dropAreaRef}
          className="dropandselect"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <label htmlFor="fileUpload">
            <UploadIcon/>
            <p>Drag & Drop or <span> Choose file </span> to upload</p>
          <input
            type="file"
            id="fileUpload"
            name="fileUpload"
            multiple
            onChange={handleInputChange}
            />
            </label>
            </div>

          <br />
          <div className="uploadInventory">
            {uploadedFiles.map((upload, index) => (

              <div key={index} className="upperdiv">
                    <UploadFileIcon/>
                <div className="uploaddata">
                  <div className='sidedata'>
                    <p>{upload.file.name}</p>
                    <span style={{ cursor: 'pointer', lineHeight: '1' }} onClick={()=> handleCancelFile(index) } >
                      <CloseIcon/>
                    </span>
                  </div>
                  <div className="mb-1 progress">
                    <p className="filesize">{formatSize(upload.file.size)} </p>
                    {/* <p className="filesize">{upload.progress}%</p> */}
                  </div>
                  {/* <Progress completed="100" /> */}
                  {upload.uploading ?
                      <ProgressBar 
                       bgColor="#19740D" 
                       margin='0px' 
                       labelAlignment="center" 
                       height='10px'
                       borderRadius="4px"
                       labelSize='10px'
                       customLabel="complete"
                       completed={100} 
                       />
                     :
                     <ProgressBar 
                        bgColor="#E72F2F"
                        margin='0px' 
                        labelAlignment="center" 
                        height='10px'
                        borderRadius="4px"
                        labelSize='10px'
                        customLabel="processing...."
                        completed={100} 
                        />
                  }

                </div>
              </div>

            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className='footerbtn'>
          <Button variant="secondary" onClick={close}>
            Close
          </Button>

         {uploadedFiles.length > 0 ?
          uploadedFiles.every(e=> e.uploading == true ) ?
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
                :
              <Button variant="primary">
                Wait
              </Button>
          :
          <Button variant="secondary">
            No File Found
          </Button>
          }

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InventoryModal;
