import React, { useEffect, useState,  useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { minimumTimeSpent } from '../env';

const TimerTracker = () => {
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const params = useParams();
    let { uuid } = params;
    const [startTime, setStartTime] = useState(Date.now());
    const [elapsedTime, setElapsedTime] = useState(0);
    const [apiCallMade, setApiCallMade] = useState(false);
    const [closingTab, setClosingTab] = useState(false);
    const [mouseInactive, setMouseInactive] = useState(false);

    const elapsedTimeRef = useRef(elapsedTime);

    const sendDataToApi = async(time) => {
        try {
            let _data = {
                time: Math.floor(time / 1000),
                startTime : moment(startTime).toISOString(),
                endTime : moment().toISOString(),
                projectUUID : uuid,
                userUUID : userData.user.uuid
            };

            if(_data.time >= minimumTimeSpent ){
                await axios.post(`${configData.config.API_URL}/api/dashboard/saveTimeSpend`, _data, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                        "Content-Type": "application/json"
                    }
                });
            }

            setApiCallMade(true);
            setElapsedTime(0);
        } catch (error) {
            console.error('Error hitting API:', error);
        }
    };

    useEffect(() => {
        let mouseMoveTimeout;

        const handleMouseMove = () => {
            // Reset the timeout whenever mouse is moved
            clearTimeout(mouseMoveTimeout);
            setMouseInactive(false);
            
            // Set a new timeout for 5 minutes
            mouseMoveTimeout = setTimeout(() => {
                console.log("mouse inactive more then 5 min. ");
                setMouseInactive(true);
            }, 5 * 60 * 1000); // 5 minutes in milliseconds
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(mouseMoveTimeout);
        };
    }, []);

    useEffect(() => {
        // Check if the mouse has been inactive for more than 5 minutes
        if (mouseInactive && !apiCallMade && !closingTab) {
            sendDataToApi(elapsedTime);
        }
    }, [mouseInactive, apiCallMade, closingTab, elapsedTime]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden && !apiCallMade && !closingTab) {
                sendDataToApi(elapsedTime);
            } else {
                // User returned to the tab
                setStartTime(Date.now());
                setApiCallMade(false);
            }
        };

        const handleBeforeUnload = (event) => {
            // Handle the case when the user is closing the tab or browser
            if (!apiCallMade && !closingTab) {
                setClosingTab(true); // Set closingTab to true to indicate the component is handling beforeunload
                sendDataToApi(elapsedTime);
                // Note: Returning a string here will prompt a confirmation dialog.
                // Use it if you want to provide a custom message to the user.
                // return 'Are you sure you want to leave?';
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Cleanup event listeners
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [elapsedTime, userData, configData, apiCallMade, closingTab]);

    // Update elapsed time every second
    useEffect(() => {
        const intervalId = setInterval(() => {

            if (mouseInactive) {
                setStartTime(Date.now());
                setApiCallMade(false);
            } else {
                setElapsedTime(Date.now() - startTime);
                elapsedTimeRef.current = Date.now() - startTime;
            }


        }, 1000);

        return () => clearInterval(intervalId);
    }, [startTime, mouseInactive]);

    useEffect(() => {
        return () => {
          // This code runs when the component is unmounted
          if(elapsedTimeRef.current != 0){
              sendDataToApi(elapsedTimeRef.current);
            }
        };
      }, []);


    return null;
};

export default TimerTracker;