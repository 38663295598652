import React from 'react';
import loader from './assets/images/loader.gif';

const Loader = () => {

    return (
        <div className='loader'>
            <img src={loader} alt="" />
        </div>
    );
}

export default Loader
