import { createSlice } from "@reduxjs/toolkit";

const initialState={
    slice:{}
}


const sceneSlice = createSlice({
   name:"sceneSlice",
   initialState,
   reducers:{
    saveSlice:(state,action)=>{
        state.slice = action.payload.detail
    },
    removeSlice:()=>{
        return initialState;
    }
   }
});

export const { saveSlice , removeSlice } = sceneSlice.actions;
export default sceneSlice.reducer;