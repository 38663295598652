import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import Tooltip from '@mui/material/Tooltip';
import { OrbitControls } from 'three-stdlib';
import default360 from "../../../assets/images/default360.jpg";
import configSlice from '../../../store/slice/configSlice';
import HotspotDataModal from '../Modal/HotspotDataModal';
import { removeSlice, saveSlice } from '../../../store/slice/sceneSlice';
import { AddIcon, ExpandIcon, ReloadIcon } from '../../../assets/SvgIcons';

const SceneDashboard = ({ details , hotSpotType, selectHotspotData, setHostpotLinkData, setHotSpotNull, reloadScene, sceneView, updateHotspotList, hotspotSize, handleClickFullscreen, cancelEditHotspot }) => {
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const dispatch = useDispatch();
  
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [texture, setTexture] = useState(new TextureLoader().load(default360));
  const [scene , setScene] = useState(new THREE.Scene());
  const [cameraView , setCameraView] = useState({x:0,y:0,z:0});
  const [camera , setCamera] = useState(new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000));
  const [renderer , setRenderer] = useState(new THREE.WebGLRenderer({antialias : true}));
  const [mouse , setMouse] = useState(new THREE.Vector2());
  const [raycaster , setRaycaster] = useState(new THREE.Raycaster());
  const [sceneDetails, setSceneDetails] = useState({});
  const [hotspotModalData, setHotspotModalData] = useState({});
  const [modal, setModal] = useState(false);
  const[currentSceneUUID , setCurrentSceneUUID] = useState(null)
  const [hotspotList, setHotspotList] = useState([]);
  const [defaultHotspotList, setDefaultHotspotList] = useState([]);
  const [selectedHotspotUUID , setSelectedHotspotUUID] = useState(null);

  let enableMoveCanvas = false;
  const newHotspotTypeRef = useRef(null);
  const mountRef = useRef();
  const controls = useRef();
  const videoRef = useRef(null);

  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov'];
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'];

  const saveSceneCameraAngle = async() =>{
    const currentPosition = camera.position;
    let _data = {
      cameraView:{
        xAxis:currentPosition.x,
        yAxis:currentPosition.y,
        zAxis:currentPosition.z,
      }
    }
    await axios.post(`${configData.config.API_URL}/api/scene/save/cameraView/${sceneDetails.uuid}`, _data, {
      headers: {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json"
       }
      });
  }

  const handleRecenterScene =() =>{
     if("inventory" in sceneDetails){
       setCameraView({ 
         x:sceneDetails.cameraView.xAxis, 
         y:sceneDetails.cameraView.yAxis,  
         z:sceneDetails.cameraView.zAxis 
       });
   }
   }

  const updateSceneDetails=()=>{
    if("inventory" in sceneDetails){
      if(sceneDetails && sceneDetails.cameraView.xAxis ){
        setCameraView({ 
          x:sceneDetails.cameraView.xAxis, 
          y:sceneDetails.cameraView.yAxis,  
          z:sceneDetails.cameraView.zAxis 
        });
      }
      setTexture(new TextureLoader().load(sceneDetails.inventory.path));
    }
   };




  const handleHotspotClick = (event, hotspotSprite) => {

      if(!newHotspotTypeRef.current){   // Add Hotspot Button is active or not
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(hotspotSprite);

      if (intersects.length > 0 ) {
         if(!selectedHotspotUUID){
            setHotspotModalData({});
            if(hotspotSprite.userData.hotspotType == "LINK"){
              getSceneDetail(hotspotSprite.userData.hotspotData.LINK, false);
            }else{
              if(videoRef.current){
                videoRef.current.pause();
               }

              setModal(true)
              setHotspotModalData({
                type:hotspotSprite.userData.hotspotType,
                data:hotspotSprite.userData.hotspotData
              });
            } 
        }
      }
      }

    };

  const handleHotspotHover = (event, hotspotSprite) => {
      if(newHotspotTypeRef.current) return;
    
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      
     // Reset scale for all hotspots
      scene.children.forEach((child) => {
        if (child.userData.type === 'hotspot') {
              child.scale.set(child.userData.originalScale.x, child.userData.originalScale.y, child.userData.originalScale.z);
           }
       });


      if (intersects.length > 0) {
        const intersectedHotspot = intersects[0].object;
        if (intersectedHotspot.userData.type === 'hotspot') {
          intersectedHotspot.scale.set(
            intersectedHotspot.userData.originalScale.x * 1.5,
            intersectedHotspot.userData.originalScale.y * 1.5,
            intersectedHotspot.userData.originalScale.z * 1.5
          );
        }
      }
    };

  const endsWithAny = (str, extensions) => {
      return extensions.some(extension => str.toLowerCase().endsWith(extension));
    }

  const getSceneDetail =  async(uuid, type) =>{
      try {
        let existingVideo = videoRef.current;
        if (existingVideo) {
          existingVideo.pause();
          existingVideo.src = "";
          existingVideo.load();
          videoRef.current = null;
        }
        
        setSelectedHotspotUUID(null);
        setCurrentSceneUUID(uuid);
        dispatch(removeSlice());

        let{ data } = await axios.get(`${configData.config.API_URL}/api/scene/detail/${uuid}`,{
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
             }
            });

            setSceneDetails({...data.data});
            dispatch(saveSlice({ detail:data.data  }));
            let hotspots = data.data.hotspots;

            setHotspotList([...hotspots])
            updateHotspotList([...hotspots])
            setDefaultHotspotList([...hotspots]);

            let filePath = data.data.inventory.path;
            let isFileImage = endsWithAny(filePath, imageExtensions);

            let newTexture;

            if(isFileImage){
              newTexture = new TextureLoader().load(data.data.inventory.path);
            }else{
              const video = document.createElement('video');
              video.src = data.data.inventory.path;
              video.crossOrigin = 'anonymous';
              video.loop = true;
              video.muted = false;
              video.play();
              videoRef.current = video;
              newTexture = new THREE.VideoTexture(video);
            }
            
            setTexture(newTexture);

            const view = type ? camera.position : {
              x: data.data.cameraView.xAxis,
              y: data.data.cameraView.yAxis,
              z: data.data.cameraView.zAxis
            };

            setCameraView({ 
              x: view.x, 
              y: view.y,  
              z: view.z 
            });
            
          } catch (error) {
            console.log(error.message)
          }
        }

  const getCoordinates = (event) => {
    console.log("enableMoveCanvas ", enableMoveCanvas);
    console.log("newHotspotTypeRef.current ", newHotspotTypeRef.current);
      if(enableMoveCanvas && newHotspotTypeRef.current ){
        let xPoint = (event.clientX / window.innerWidth) * 2 - 1;
        let yPoint = -(event.clientY / window.innerHeight) * 2 + 1;
        mouse.x = xPoint;
        mouse.y = yPoint;
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObject(scene);

        if(intersects.length > 0){
          const existingHotspot = intersects.find((obj) => obj.object.userData && obj.object.userData.type === 'hotspot');
          if (!existingHotspot) {
              const { x, y, z } = intersects[0].point;
              setHostpotLinkData({
                      hotSpotType, 
                      x, 
                      y, 
                      z, 
                      sceneUUID:sceneDetails.uuid
                    });
          }else{
            alert('A hotspot found at this location.');
            console.log('A hotspot found at this location.');
          }}
        } else if(enableMoveCanvas &&  "uuid" in selectHotspotData && selectedHotspotUUID){
          
          let xPoint = (event.clientX / window.innerWidth) * 2 - 1;
          let yPoint = -(event.clientY / window.innerHeight) * 2 + 1;
          mouse.x = xPoint;
          mouse.y = yPoint;
          raycaster.setFromCamera(mouse, camera);
          const intersects = raycaster.intersectObject(scene);

          if(intersects.length > 0){
            const existingHotspot = intersects.find((obj) => obj.object.userData && obj.object.userData.type === 'hotspot');
            if (!existingHotspot) {
                const { x, y, z } = intersects[0].point;        
                let currentHotspot = [...hotspotList];
                const updatedData = currentHotspot.map(item => {
                  if (item.uuid === selectedHotspotUUID) {
                    return {
                      ...item,
                      hotpotView: {
                        ...item.hotpotView,
                        x, 
                        y, 
                        z
                      }
                    };
                  } else {
                    return item;
                  }
                });

                setHotspotList([...updatedData]);
                updateHotspotList([...updatedData])
                const currentPosition = camera.position;
                setCameraView({
                    x:currentPosition.x,
                    y:currentPosition.y,
                    z:currentPosition.z,
                })    
            }else{
              alert('A hotspot found at this location.');
              console.log('A hotspot found at this location.');
            }}
        }
      
      }

  const getImageDimensions = async(url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;
        img.src = url;
      });
    };

  const loadHotspot = async(hotspotList, selectHotspotId) =>{

        while (scene.children.length > 0) {
          const child = scene.children[0];
          renderer.domElement.removeEventListener('click', child.userData.clickHandler);
          child.material.map.dispose(); // Dispose of hotspot texture
          scene.remove(child);
        }

    // Create a sphere geometry for the 360 image
      const geometry = new THREE.SphereGeometry(500, 40, 60);
      geometry.scale(-1, 1, 1);
      const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
      const sphere = new THREE.Mesh(geometry, material);  
      scene.add(sphere);

      if(hotspotList.length === 0 || !hotspotList.length ) return;

        let filteredList = selectHotspotId !== null ? hotspotList.filter((e) => e.uuid === selectHotspotId) : hotspotList;

        for(let i in filteredList){

            let e = filteredList[i];

            const hotspotTexture = new THREE.TextureLoader().load(e.inventory.path);
            
            hotspotTexture.minFilter = THREE.LinearFilter;
            hotspotTexture.magFilter = THREE.LinearFilter;
            hotspotTexture.mapping = THREE.EquirectangularReflectionMapping;
            hotspotTexture.encoding = THREE.sRGBEncoding;
            hotspotTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
            const hotspotMaterial = new THREE.SpriteMaterial({ 
                                      map: hotspotTexture, 
                                      // color: 0xffffff, // set color of hotspot
                                      // transparent: true,
                                      // opacity: 1,
                                      side: THREE.DoubleSide,
                                      toneMapped : false,
                                      rotation: 0,  // rotate hotspot
                                      //sizeAttenuation: true,
                                      depthTest: false,  // Hide some part of hotspot
                                    });
  
            const hotspotSprite = new THREE.Sprite(hotspotMaterial);
    
            hotspotSprite.scale.set(e.hotpotView.scaleX, e.hotpotView.scaleY, e.hotpotView.scaleZ);
                              
            hotspotSprite.position.set(e.hotpotView.x, e.hotpotView.y, e.hotpotView.z);
          
            // Add click event for hotspots
              hotspotSprite.userData = {
                type: 'hotspot',
                id: e.id,
                hotspotType: e.type,
                hotspotData: e.hotpotView.hotspotData,
                originalScale: new THREE.Vector3( e.hotpotView.scaleX, e.hotpotView.scaleY, e.hotpotView.scaleZ)
              };
            
          // Save the reference to handleHotspotClick
              const clickHandler = (event) => handleHotspotClick(event, hotspotSprite);
              renderer.domElement.addEventListener('click', clickHandler);
              hotspotSprite.userData.clickHandler = clickHandler;
              scene.add(hotspotSprite);
                        
              const hoverHandler = (event) => handleHotspotHover(event, hotspotSprite);
              renderer.domElement.addEventListener('mousemove', hoverHandler);
            
          };

  }

  useEffect(()=>{ 
    updateSceneDetails();
    return () =>{
      let existingVideo = videoRef.current;
        if (existingVideo) {
          existingVideo.pause();
          existingVideo.src = "";
          existingVideo.load();
          videoRef.current = null;
        }
    }
   },[]);

  useEffect(()=>{ 
    sceneView && saveSceneCameraAngle();
    cancelEditHotspot && setHotspotList([...defaultHotspotList]);
    newHotspotTypeRef.current = hotSpotType;
    if(reloadScene){setHotSpotNull(null); if("uuid" in sceneDetails){ getSceneDetail(sceneDetails.uuid , true) }; }
    
   },[sceneView, cancelEditHotspot, reloadScene, hotSpotType ]);

    useEffect(()=>{ 
      if("uuid" in selectHotspotData){
        setSelectedHotspotUUID(selectHotspotData.uuid);
        loadHotspot(hotspotList, selectHotspotData.uuid);
      }else{
        setSelectedHotspotUUID(null)
        loadHotspot(hotspotList, null);
      }
    },[selectHotspotData]);

    useEffect(()=>{
      if("inventory" in details){
        if(currentSceneUUID != details.uuid){
          getSceneDetail(details.uuid, false);
          setSceneDetails({...details});
        }
      }
    },[details]);

  useEffect(()=>{
      renderer.domElement.removeEventListener('click', handleHotspotClick);
      renderer.domElement.removeEventListener('mousemove', handleHotspotHover);

      loadHotspot(hotspotList, selectedHotspotUUID);      

      return () => {
        renderer.domElement.removeEventListener('click', handleHotspotClick);
        renderer.domElement.removeEventListener('mousemove', handleHotspotHover);

      // Remove all hotspots
       scene.children.forEach((child) => {   
        if (child.userData.type === 'hotspot') {
          renderer.domElement.removeEventListener('click', child.userData.clickHandler);
          child.material.map.dispose();
          scene.remove(child);
             }
         });

      };

    },[hotspotList]);
    

  useEffect(() => {
    if (!mountRef.current) return;

     // Set up Three.js scene, camera, and renderer
     renderer.setSize(window.innerWidth, window.innerHeight);
     renderer.setPixelRatio(window.devicePixelRatio * 2);

     texture.mapping = THREE.EquirectangularReflectionMapping;
     texture.encoding = THREE.sRGBEncoding;
     texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

     mountRef.current.appendChild(renderer.domElement);
     
    // Create a sphere geometry for the 360 image
      const geometry = new THREE.SphereGeometry(500, 40, 60);
      geometry.scale(-1, 1, 1);
      const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
      const sphere = new THREE.Mesh(geometry, material);  
      scene.add(sphere);

      // Set up OrbitControls
      controls.current = new OrbitControls(camera, renderer.domElement);
      controls.current.enableZoom = true;
      controls.current.minDistance = 0.1; 
      controls.current.maxDistance = 250;
      controls.current.zoomSpeed = 2.0;
      controls.current.rotateSpeed = -0.5;
      
      // Enable damping for smooth transition
      // controls.current.enableDamping = true;  // Enable damping (inertia)
      // controls.current.dampingFactor = 0.06;  // Damping factor, lower values make smoother but slower
      
      controls.current.autoRotate = false;

      // controls.current.maxDistance = 60;
      // controls.current.autoRotateSpeed = 2;

      controls.current.target0.copy(new THREE.Vector3(0, 0, 0));
      controls.current.position0.copy(new THREE.Vector3(cameraView.x, cameraView.y, cameraView.z));
      controls.current.zoom0 = 1;
      controls.current.reset();

    // Set the camera position
      //  camera.position.set(cameraView.x, cameraView.y, cameraView.z);
      //  camera.lookAt(0,0,0);
      //  camera.position.z = 1;

      let cameraZView = cameraView.z.toFixed(3) == 0.00 ? 0.05 : cameraView.z.toFixed(3);
      camera.position.z =  cameraZView;

    // Handle window resize
      window.addEventListener('resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      });

    // Animation function
        const animate = () => {
            requestAnimationFrame(animate);
            controls.current.update();
            renderer.render(scene, camera);
          };

      animate();

    // Cleanup on component unmount
          return () => {
          controls.current.dispose();
          texture.dispose();
          scene.traverse((object) => {
            if (object.material) {
              object.material.dispose();
            }
          });
          
          if (mountRef.current && mountRef.current.contains(renderer.domElement)) {
              mountRef.current.removeChild(renderer.domElement);
          }
        }

    }, [texture, cameraView]);

  const selectUpdateSelectHotspotData = async(currentHotspot) =>{

    const updatedData = [];

    for(let i in currentHotspot){
        let item = currentHotspot[i];
        if (item.uuid === selectedHotspotUUID) {
          let {width, height} = await getImageDimensions(item.inventory.path)
          updatedData.push({
            ...item,
            hotpotView: {
              ...item.hotpotView,
              scaleX: width * parseFloat(hotspotSize),
              scaleY: height * parseFloat(hotspotSize),
              scaleZ: 0,
            }
          })

        } else {
          updatedData.push(item);
        }
      }

      setHotspotList([...updatedData]);
      updateHotspotList([...updatedData]);
        const currentPosition = camera.position;
      setCameraView({
          x:currentPosition.x,
          y:currentPosition.y,
          z:currentPosition.z,
      });

  }

  useEffect(()=>{
      if(selectedHotspotUUID){
        let currentHotspot = [...hotspotList];
        selectUpdateSelectHotspotData(currentHotspot)
      }
    },[hotspotSize]);

  const expandFullScreen = () =>{
     let value = isFullScreen;
     setIsFullScreen(!value);
     handleClickFullscreen(!value)
  }

  const handleCloseModal = (e) =>{
         if(videoRef.current){
          videoRef.current.play();
         }
         setModal(e)
  }

 const getMouseDown=()=>{ enableMoveCanvas = true }
 const getMouseMove=()=>{ enableMoveCanvas = false }

  return(
    <>
    <div onMouseUp={getCoordinates} onMouseDown={getMouseDown} onMouseMove={getMouseMove} >
        <div ref={mountRef} />

       {"inventory" in sceneDetails &&
          <div>
            <Tooltip title="Recenter" arrow placement="left" onClick={handleRecenterScene}>
              <span className={ isFullScreen ? 'enlarge-icons true' : 'enlarge-icons '} > 
              <ReloadIcon/>
                </span>
            </Tooltip>

            <Tooltip title="Full Screen" arrow placement="left" onClick={expandFullScreen}>
            <span className={ isFullScreen ? 'recenter-icons true' :  'recenter-icons' }>
                  <ExpandIcon/>
            </span>
            </Tooltip>
          </div>
        }

    </div>
    <HotspotDataModal modal={modal} setModal={(e)=>handleCloseModal(e)} hotspotModalData={hotspotModalData} />
    </>
    );
};

export default SceneDashboard;