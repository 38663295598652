import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { debounce } from '../../../utils';



const ConfirmBox = ({title, message, show, handleConfirm , setShow }) => {


    const debounceConfirmClick = debounce(handleConfirm, 750);

    return (
        <>
            <Modal  centered show={show} onHide={setShow}>
            <Modal.Header className="popupheader delete pt-4">
                        {title}
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group pt-4 pb-5 m-0">
                                    <p className="popupheading delete p-0 m-0">{message}</p>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                                            <button className="createbtn cancelbtn" onClick={setShow} >Cancel</button>
                                            <button className="ms-3 createbtn" onClick={debounceConfirmClick} >Confirm</button>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmBox
