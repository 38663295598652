import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useFormik } from "formik";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CloseIcon } from '../../../assets/SvgIcons';
import { useSelector } from 'react-redux';
import { projectCreateValidation } from '../validation';

const CreateProjectModal = ({ createModal ,  closeCreateModal, getProjectList }) => {
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);

  const formik = useFormik({
      initialValues: {
         name:"",
         appName:""
      },
      validationSchema: projectCreateValidation,
      onSubmit: async (values) => {
          try {
            let _data = {
              name: values.name,
              appName: values.appName
             }

        await axios.post(`${configData.config.API_URL}/api/project/create` , _data, {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Content-Type": "application/json"
            }});
            
        getProjectList();
      } catch (error) {
        console.log(error)
        alert("Something went wrong when create project.")
      }finally{
            close();
          }
      }

    });

    const close = () =>{
      formik.resetForm()
      closeCreateModal();
    }

  return (
    <>
    <Modal show={createModal} onHide={close} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header className='modalheader'>
            Add New Project
            <span onClick={close} ><CloseIcon/></span>
        </Modal.Header>

        <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
        <div className='row' >
          <div className='col-12 standardinput' >
                <label htmlFor="name">
                  <div> Project Name<span className='asterisk'>*</span> </div>
                <input type="text" id="Name" name="name" placeholder='Name' value={formik.values.name} onChange={(event)=>formik.setFieldValue(event.target.name, event.target.value)} className={`form-control ${formik.errors.name && formik.touched.name ? 'invalid' : ''}`} />
                  <p className="error" >
                    {formik.errors.name && formik.touched.name && 
                     <>
                      {formik.errors.name}
                      </>
                    }
                  </p>
                </label>
                <label className='mt-2' htmlFor="appName" >
                <div>Application Name<span className='asterisk'>*</span> </div>
                <input type="text" id="appName" name="appName" placeholder='Application Name' value={formik.values.appName} onChange={(event)=>formik.setFieldValue(event.target.name, event.target.value)} className={`form-control ${formik.errors.appName && formik.touched.appName ? 'invalid' : ''}`} />
                  <p className="error" >
                  {formik.errors.appName && formik.touched.appName && 
                  <>
                  {formik.errors.appName}
                  </>
                  }
                  </p>
                </label>
          </div>
             </div>
        
        </Modal.Body>

        <Modal.Footer className='footerbtn'>
        <Button variant="secondary" onClick={close}> Cancel </Button>
        <Button variant="primary" type="submit" > Create </Button>
        </Modal.Footer>
        </form>

    </Modal>

    </>
  )
}

export default CreateProjectModal