import React, { useState } from 'react';

const PdfViewer = ({ pdfUrl }) => {
  return (
    <div className='pdfviewer'>
      <iframe src={pdfUrl} width="100%" height="450px" title="Pdf"></iframe>
    </div>
  );
};

export default PdfViewer;
