import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate , useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dashboard from './Pages/MyDashboard/Dashboard'
import TeamDashboard from './Pages/TeamDashboard/TeamDashboard'
import ProjectsDashboard from './Pages/Project/ProjectsDashboard'
import ProjectEditDashboard from './Pages/Project/EditDashboard'
import Trash from './Pages/Trash'
import ProjectPreview from './Pages/Project-Preview/ProjectPreview'


const AppRouter = () => {
    const userData = useSelector((state) => state.userSlice);
 
    return (
        <>
            <Routes>
                <Route path='/dashboard/:UUID' element={<Dashboard/>}/>
                <Route path='/team-dashboard/:groupUUID' element={<TeamDashboard/>}/>
                <Route path='/projects' element={<ProjectsDashboard />}/>
                <Route path='/projects/edit/:uuid' element={<ProjectEditDashboard/>}/>
                <Route path='/trash' element={<Trash/>}/>
                <Route path='/preview/project/:uuid' element={<ProjectPreview/>}/>
                <Route path='*' element={<Navigate to={`/dashboard/${userData.user.uuid}`} state={{ name: userData.user.name, email: userData.user.email, uuid: userData.user.uuid }} /> } />
            </Routes>
        </>
    )
}

export default AppRouter