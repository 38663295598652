import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import { OrbitControls } from 'three-stdlib';
import default360 from "../../../assets/images/default360.jpg";
import { useParams } from 'react-router-dom';
import { previewApi } from '../../../env';
import HotspotDataModal from "../Modal/HotspotDataModal"
import ProjectPreviewHeader from './ProjectPreviewHeader';
import { TurnSharpLeftRounded } from '@mui/icons-material';


const ProjectPreview = () => {
  const userData = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const controls = useRef();
  const mountRef = useRef();
  const loaderRef = useRef(null);
  const videoRef = useRef(null);
  const params = useParams();
  const { uuid } = params;
  const [texture, setTexture] = useState(new TextureLoader().load(default360));
  const [scene , setScene] = useState(new THREE.Scene());
  const [cameraView , setCameraView] = useState({x:0,y:0,z:0});
  const [camera , setCamera] = useState(new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000));
  const [renderer , setRenderer] = useState(new THREE.WebGLRenderer());
  const [sceneDetails, setSceneDetails] = useState({});
  const [projectData, setProjectData] = useState({});
  const [hotspotList, setHotspotList] = useState([]);
  const [hotspotModalData, setHotspotModalData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loaderText, setLoaderText] = useState(null);
  const [modal, setModal] = useState(false);
  const [fileExist ,setFileExist] = useState(true);
  const [error,setError] = useState(null);
  const [textureLoad , setTextureLoad] = useState(TurnSharpLeftRounded);

  const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov'];
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp'];

  const endsWithAny = (str, extensions) => {
    return extensions.some(extension => str.toLowerCase().endsWith(extension));
  }
  
  const updateScene = async() =>{  

      let existingVideo = videoRef.current;
      if (existingVideo) {
        existingVideo.pause();
        existingVideo.src = "";
        existingVideo.load();
        videoRef.current = null;
      }

    let filePath = sceneDetails.inventory.path;
    let isFileImage = await endsWithAny(filePath, imageExtensions);

    let newTexture;

      if(isFileImage){
        newTexture = new TextureLoader().load(filePath);
      }else{
        const video = document.createElement('video');
        video.src = filePath;
        video.crossOrigin = 'anonymous';
        video.loop = true;
        video.muted = false;
        video.play();
        videoRef.current = video;
        newTexture = new THREE.VideoTexture(video);
      }

      setCameraView({ 
        x:sceneDetails.cameraView.xAxis, 
        y:sceneDetails.cameraView.yAxis,  
        z:sceneDetails.cameraView.zAxis 
      });
      setTexture(newTexture);
      setHotspotList([...sceneDetails.hotspots]);
      setTextureLoad(true);
      
  };

  useEffect(()=>{
    setHotspotList([]);
    if("uuid" in sceneDetails ){
           updateScene();
         }
  },[sceneDetails]);

  const handleHotspotClick = (event, hotspotSprite) => {
    // Add Hotspot Button is active or not
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObject(hotspotSprite);

    if (intersects.length > 0 ) {
          setHotspotModalData({});
          if(hotspotSprite.userData.hotspotType == "LINK"){
               let selectSceneUUID = hotspotSprite.userData.hotspotData["LINK"];
               let findScene = projectData.scenes.filter(e => e.uuid == selectSceneUUID)[0]
               if(findScene){
                loaderRef.current.style.display = 'flex';
                setSceneDetails({...findScene});
               }
          } else{
            setModal(true)
            setHotspotModalData({
              type:hotspotSprite.userData.hotspotType,
              data:hotspotSprite.userData.hotspotData
            });
          } 
    }
  };

  const handleHotspotHover = (event, hotspotSprite) => {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    
   // Reset scale for all hotspots
    scene.children.forEach((child) => {
      if (child.userData.type === 'hotspot') {
            // child.scale.set(child.userData.originalScale.x, child.userData.originalScale.y, child.userData.originalScale.z);
            child.scale.copy(child.userData.originalScale);
         }
     });

    if (intersects.length > 0) {
      const intersectedHotspot = intersects[0].object;
      if (intersectedHotspot.userData.type === 'hotspot') {
        intersectedHotspot.scale.set(
          intersectedHotspot.userData.originalScale.x * 1.5,
          intersectedHotspot.userData.originalScale.y * 1.5,
          intersectedHotspot.userData.originalScale.z * 1.5
        );
      }
    }
  };

  const loadHotspot = async() =>{

    if(hotspotList.length > 0){

      for(let i in hotspotList ){
        let e = hotspotList[i]
  
        const hotspotTexture = new THREE.TextureLoader().load(e.inventory.path);
      
        hotspotTexture.minFilter = THREE.LinearFilter;  // improve hotspot quality
        hotspotTexture.magFilter = THREE.LinearFilter;  // improve hotspot quality 
        hotspotTexture.mapping = THREE.EquirectangularReflectionMapping;
        hotspotTexture.encoding = THREE.sRGBEncoding;
        hotspotTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        const hotspotMaterial = new THREE.SpriteMaterial({ 
          map: hotspotTexture, 
          // color: 0xffffff, // set color of hotspot
          // transparent: true,
          // opacity: 1,
          side: THREE.DoubleSide,
          toneMapped : false,
          rotation: 0,  // rotate hotspot
          //sizeAttenuation: true,
          depthTest: false,  // Hide some part of hotspot
        });
                                
        const hotspotSprite = new THREE.Sprite(hotspotMaterial);
        hotspotSprite.scale.set(e.hotpotView.scaleX, e.hotpotView.scaleY, e.hotpotView.scaleZ);
        
        hotspotSprite.position.set(e.hotpotView.x, e.hotpotView.y, e.hotpotView.z);

        // Add click event for hotspots
          hotspotSprite.userData = {
            type: 'hotspot',
            id: e.id,
            hotspotType: e.type,
            hotspotData: e.hotpotView.hotspotData,
            originalScale: new THREE.Vector3(e.hotpotView.scaleX, e.hotpotView.scaleY, e.hotpotView.scaleZ)
          };
        
        // Save the reference to handleHotspotClick
          const clickHandler = (event) => handleHotspotClick(event, hotspotSprite);
          renderer.domElement.addEventListener('click', clickHandler);
          hotspotSprite.userData.clickHandler = clickHandler;
          scene.add(hotspotSprite);

          const hoverHandler = (event) => handleHotspotHover(event, hotspotSprite);
          renderer.domElement.addEventListener('mousemove', hoverHandler);

      }

    }

  }

  useEffect(()=>{
    renderer.domElement.removeEventListener('click', handleHotspotClick);
    renderer.domElement.removeEventListener('mousemove', handleHotspotHover);

      // Remove All Hotspot and it's click function
      while (scene.children.length > 0) {
        const child = scene.children[0];
        renderer.domElement.removeEventListener('click', child.userData.clickHandler);
        child.material.map.dispose(); // Dispose of hotspot texture
        scene.remove(child);
      }

      loadHotspot(hotspotList);
    

    return () => {
      renderer.domElement.removeEventListener('click', handleHotspotClick);
      renderer.domElement.removeEventListener('mousemove', handleHotspotHover);

    // Remove all hotspots
     scene.children.forEach((child) => {   
      if (child.userData.type === 'hotspot') {
        renderer.domElement.removeEventListener('click', child.userData.clickHandler);
        child.material.map.dispose();
        scene.remove(child);
      }
  });

    };

  },[hotspotList]);

  const getProjectPreviewData = async() =>{
     try {
      setError(null);
      let { data } = await axios.get(`${previewApi}/api/project/viewPreview/${uuid}`,{
        headers: {
          "Authorization": `Bearer ${userData.token}`,
          "Content-Type": "application/json"
        }});

        if(data.find){
            setError(null);
            setFileExist(true);
            setProjectData({...data.data})
            if(data.data.scenes.length > 0){
              setSceneDetails({...data.data.scenes[0]});
            }
          }else{
            setError("File does not exist OR you are unauthorized.")
            setFileExist(false);
          }

     } catch (error) {
       console.log(error)
     }
  };

  useEffect(()=>{
          uuid && getProjectPreviewData(); 
  },[uuid]);

    useEffect(() => {

    if (!mountRef.current) return;

     // Set up Three.js scene, camera, and renderer
     renderer.setSize(window.innerWidth, window.innerHeight);
     renderer.setPixelRatio(window.devicePixelRatio);

     texture.mapping = THREE.EquirectangularReflectionMapping;
     texture.encoding = THREE.sRGBEncoding;
     texture.anisotropy = renderer.capabilities.getMaxAnisotropy();

     mountRef.current.appendChild(renderer.domElement);

    // Create a sphere geometry for the 360 image
      const geometry = new THREE.SphereGeometry(500, 40, 60);
      geometry.scale(-1, 1, 1);
      const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
      const sphere = new THREE.Mesh(geometry, material);
      scene.add(sphere);

    // Set up OrbitControls
      controls.current = new OrbitControls(camera, renderer.domElement);
      controls.current.enableZoom = true;
      controls.current.minDistance = 0.1; 
      controls.current.maxDistance = 250;
      controls.current.zoomSpeed = 2.0;
      controls.current.rotateSpeed = -0.5;
      controls.current.autoRotate = false;
      // controls.current.maxDistance = 60;
      // controls.current.autoRotateSpeed = 2;

      controls.current.target0.copy(new THREE.Vector3(0, 0, 0));
      controls.current.position0.copy(new THREE.Vector3(cameraView.x, cameraView.y, cameraView.z));
      controls.current.zoom0 = 1;
      controls.current.reset();
      
      // Set the camera position
      // camera.position.set(cameraView.x, cameraView.y, cameraView.z);
      // camera.lookAt(0, 0, 0);
      // camera.position.z = 5;

      let cameraZView = cameraView.z.toFixed(3) == 0.00 ? 0.05 : cameraView.z.toFixed(3);
      camera.position.z =  cameraZView;

    // Animation function
      const animate = () => {
        requestAnimationFrame(animate); 
        controls.current.update();     
        renderer.render(scene, camera);

      };

    // Handle window resize
    window.addEventListener('resize', () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    animate();

    if (textureLoad){  
      setTimeout(()=>{
         if(loaderRef.current){
           loaderRef.current.style.display = 'none';
         }
      },1000)
     }

    // Cleanup on component unmount
    return () => {
      controls.current.dispose();
      texture.dispose();
      scene.clear();
      scene.traverse((object) => {
        if (object.material) {
          object.material.dispose();
        }
      });
      
      if (mountRef.current && mountRef.current.contains(renderer.domElement)) {
          mountRef.current.removeChild(renderer.domElement);
      }
    }
  

    }, [texture]);

  return(
    <>
    {fileExist ?
     <div className='sceneLoader'>
     <div ref={mountRef} />

     <div ref={loaderRef} className='loaderhere sceneChangeLoader'>
          <div id="container">
            <span className="loading-circle sp1">
              <span className="loading-circle sp2">
                <span className="loading-circle sp3"></span>
              </span>
            </span>
          </div>
     </div>

     </div>
         :
     <p style={{marginTop :"80px"}} >{error && error }</p>
    }
  
     <ProjectPreviewHeader setLoader={setLoader} setLoaderText={setLoaderText} fileExist={fileExist} />
     <HotspotDataModal modal={modal} setModal={setModal} hotspotModalData={hotspotModalData} />
      {loader && 
        <div className='loaderhere'>
          <div id="container">
              <span className="loading-circle sp1">
                <span className="loading-circle sp2">
                  <span className="loading-circle sp3"></span>
                </span>
              </span>
              <label className="loading-title">{loaderText} ...</label>
            </div>
        </div>
      }
     </>
    );
};

export default ProjectPreview;