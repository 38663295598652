import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { MuteIcon, PauseIcon, PlayIcon, StopIcon, UnMuteIcon } from '../../../assets/SvgIcons';

const AudioWaveform = ({ audioUrl }) => {
  const waveformRef = useRef(null);
  const waveformTimelineRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    // Create WaveSurfer instance
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: 'rgb(219, 164, 167)',
      progressColor: 'rgb(202, 55, 60)',
      
      height: 30,
      responsive: true,
      hideScrollbar: true,
      barRadius: 1,
    });

    // Load audio file
    wavesurfer.current.load(audioUrl);

    // Subscribe to play and pause events
    wavesurfer.current.on('play', () => setIsPlaying(true));
    wavesurfer.current.on('pause', () => setIsPlaying(false));
    wavesurfer.current.on('finish', () => {
      setIsPlaying(false);
      setCurrentTime(0);
    });

    // Subscribe to timeupdate event
    wavesurfer.current.on('audioprocess', () => {
      setCurrentTime(wavesurfer.current.getCurrentTime());
    });

    // Subscribe to ready event to get total duration
    wavesurfer.current.on('ready', () => {
      setDuration(wavesurfer.current.getDuration());
    });

    return () => {
      // Cleanup on component unmount
      wavesurfer.current.destroy();
    };
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  };

  const handleStop = () => {
    if (wavesurfer.current) {
      wavesurfer.current.stop();
      setIsPlaying(false);
      setCurrentTime(0);
    }
  };

  const handleMuteUnmute = () => {
    if (wavesurfer.current) {
      const newMuteState = !isMuted;
      wavesurfer.current.setVolume(newMuteState ? 0 : 1);
      setIsMuted(newMuteState);
    }
  };

  const calculateProgressBarWidth = () => {
    if (wavesurfer.current) {
      const percentage = (currentTime / duration) * 100;
      return `${percentage}%`;
    }
    return '0%';
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='audioplayer my-4'> 
      <div className='auidoicon'>

      </div>
      <div className='audiomain'>
      <div ref={waveformRef}/>
      {/* <div ref={waveformTimelineRef} /> */}
      <div className='audiofunc'>
      <div>
        <button onClick={handlePlayPause}>{isPlaying ? 
        <PauseIcon/> : <PlayIcon/>
        }
        </button>
        <button onClick={handleStop}>
          <StopIcon/>
        </button>
        <button onClick={handleMuteUnmute}>{isMuted ? 
        <MuteIcon/>
        :
        <UnMuteIcon/> 
        }</button>
      </div>
      {/* <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: calculateProgressBarWidth() }} />
      </div> */}
      <p>{formatTime(currentTime)} / {formatTime(duration)}</p>
      </div>
    </div>
    </div>
  );
};

export default AudioWaveform;
