import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NoDataFound from '../NoDataFound';
import apiClient from '../apiClient';
import { GROUP_LIST } from '../apiClient/apiEndpoints';

const Groups = ({ search }) => {
    const { groupUUID } = useParams();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const [groupList, setGroupList] = useState([]);

    const fetchGroupsList = async () => {
          let data = await apiClient.get(`${configData?.config?.ssoUrl}${GROUP_LIST}/${groupUUID}`);
          setGroupList([...data]);
      };


    useEffect(() => {
        if (groupUUID.length > 0) {
          fetchGroupsList();
        }
      }, [groupUUID]);



    return (
        <>
        <div className="row pt-4 mt-2">
        {groupList.length > 0 ? 
             groupList.map((item, index) => {
                if(search.length && !item.name.toLowerCase().includes(search.toLowerCase())) return null;
                return(
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-2 px-2" key={index} onClick={() => navigate(`/team-dashboard/${item.uuid}`, {state : item} )}>
                        <div className='groupcardesign'>
                            {item.avatar ? (
                            <div className='avatarimg'>
                                <img src={item.avatar} referrerPolicy='no-referrer' alt="" />
                            </div>
                            )
                            :
                            <div className='initials-placeholder'>
                            {item.name && item.name.split(" ").map((word, index) => (index < 2 ? word[0].toUpperCase() : "")).join("")}
                              </div>
                            }
                            <h6 className='groupname'> {item.name} </h6>
                            <div className="groupusercount">
                                <p>{item.groups} Sub-Groups  <span className='mx-1'>|</span></p>
                                <p>{item.users} Users  </p>
                            </div>
                        </div>
                    </div>
                )
            }
            ) 
         :
         <NoDataFound heading="No Sub-Groups Found" nodataText="" />
        }
        </div>
        </>
    )
}

export default Groups