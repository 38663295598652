import axios from "axios";
import {store} from "../store/store";
import { redirectUrl } from "../env";
import { clearConfig } from "../store/slice/configSlice";
import { removeUser } from "../store/slice/userSlice";

export const getLastElement = (url) =>{
    let data = url.split("/")
    return data[data.length-1];
}

export const inventoryFileType={
    "all"   : "0",
    "image" : "1",
    "video" : "2",
    "audio" : "3",
    "pdf"   : "4",
    "other" : "5"
}
export const allowedFileTypes = ["image/jpeg", "image/png", "video/mp4", "audio/mpeg", "application/pdf"]

export const getFileType = (type) => {
    if (type.startsWith('image')) {
        return "IMAGE";
    } else if (type === 'application/pdf') {
        return "PDF";
    } else if (type.startsWith('audio')) {
         return "AUDIO";
    } else if (type.startsWith('video')) {
         return "VIDEO";
    } else {
         return "OTHER";
    }
  };

export const Logout = async(token="") => {
    try {
            var { configSlice } = store.getState();
            var ssoUrl = configSlice.config.ssoUrl ;
            localStorage.clear();
            sessionStorage.clear();
            await axios.post(`${ssoUrl}/api/auth/logout`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
            store.dispatch(clearConfig());
            store.dispatch(removeUser());

    } catch (error) {
        console.log(error);
    }finally{
        return window.location.href = `${redirectUrl}`;
    }

}


export const redirectSSO = () => {
    try {
        localStorage.clear();
        sessionStorage.clear();
        store.dispatch(clearConfig());
        store.dispatch(removeUser());
        return window.location.href = `${redirectUrl}`;
    } catch (error) {
        console.log(error)
    }

}

export function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }